import React, { Component } from "react";
import { Header } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Button, Icon } from "semantic-ui-react";
import packageJson from "../../package.json";

class SelectFrontPage extends Component {
  render() {
    const { languages, functions, onSelect, clientIp } = this.props;

    const select = (type) => (e) => {
      e.preventDefault();
      onSelect(type);
    };

    return (
      <React.Fragment>
        <div className="select-frontpage">
          <Header as="h1">
            {languages &&
              languages != null &&
              languages.SELECT_APPOINTMENT_TYPE}
          </Header>
          {functions.map((f, idx) => (
            <Button
              icon
              key={`frontpage-function-${f.text}`}
              labelPosition="left"
              color={f.color ?? "blue"}
              size="massive"
              onClick={select(f)}
            >
              <Icon name={f.icon ?? "calendar"} />
              {languages && languages != null && languages[f.text]
                ? languages[f.text]
                : null}
            </Button>
          ))}
        </div>

        <div
          style={{
            position: "absolute",
            left: "5px",
            bottom: "5px",
            fontSize: "15px",
          }}
        >
          [ v{packageJson.version}
          {clientIp ? ` ${clientIp}` : ""}]
        </div>
      </React.Fragment>
    );
  }
}
SelectFrontPage.propTypes = {
  languages: PropTypes.object,
  functions: PropTypes.array,
  onSelect: PropTypes.func,
  clientIp: PropTypes.string,
};
export default SelectFrontPage;
