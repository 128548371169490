import React, { Component } from "react";
import PropTypes from "prop-types";
import finland from "../images/finland.svg";
import swedish from "../images/swedish.svg";
import english from "../images/english.svg";

class Language extends Component {
  render() {
    return (
      <div className="language">
        <img
          src={finland}
          alt="Suomi"
          onClick={() => this.props.changelanguage("fi")}
        />
        <img
          src={swedish}
          alt="Swedish"
          onClick={() => this.props.changelanguage("se")}
        />
        <img
          src={english}
          alt="English"
          onClick={() => this.props.changelanguage("en")}
        />
      </div>
    );
  }
}
Language.propTypes = {
  changelanguage: PropTypes.func.isRequired,
};
export default Language;
