import React, { Component, lazy, Suspense } from "react";
import { Router, Route, Redirect } from "react-router-dom";
import SelfService from "./../pages/SelfService";
//import Admin from "./../pages/Admin";
import history from "../helpers/History";
import BarcodeListener, {
  BarcodeContext,
} from "./../components/BarcodeListener";
import Login from "./../pages/Login";
import ApiCall from "./../api/ApiCall";
import History from "../helpers/History";
import ErrorHandler from "../components/ErrorHandler";
import configuration from "../configuration";

const Admin = lazy(() => import("./../pages/Admin"));

class Routes extends Component {
  constructor(props) {
    super(props);
    this.loginInterval = null;
  }
  /***
   * Setup automatic relogin => refresh token automatically in intervall
   * Setup route change listener, if new route that is not login then set  auto login interval
   */
  componentDidMount() {
    //On first load
    this.setupAutomaticRelogin();
    History.listen((location, action) => {
      //When url changes
      if (
        History &&
        History.location &&
        History.location.pathname !== "/login"
      ) {
        if (this.loginInterval && this.loginInterval !== null)
          window.clearInterval(this.loginInterval);

        this.loginInterval = window.setInterval(() => {
          this.login();
        }, 3600000); //Relogin every 60 minutes automatic
      }
    });
  }
  /***
   * Setup automatic refresh of token, so that login will persist
   */
  setupAutomaticRelogin() {
    //Clear any current login intervals, if exists
    if (this.loginInterval && this.loginInterval !== null)
      window.clearInterval(this.loginInterval);

    //If current page is not "/login" page, then try to login
    if (History && History.location && History.location.pathname !== "/login") {
      this.login(); //Relogin on every page

      //Setup interval to relogin, so that the token will update itself
      this.loginInterval = window.setInterval(() => {
        this.login();
      }, 3600000); //Relogin every 60 minutes automatic
    }
  }
  /***
   * Login with existing token
   */
  async login() {
    //Try to login with existing cookie automatically
    const api = new ApiCall(true);
    let result = await api.login(undefined, undefined, api.clientIp);
    if (typeof result === "string") {
      //Move to login, if not allready in loginpage
      if (History && History.location && History.location.pathname !== "/login")
        History.push("/login"); //Move to login
    } else if (
      History &&
      History.location &&
      History.location.pathname === "/login"
    )
      History.push("/");
  }
  getUserNameAndRole = () => {
    //Get from localstorage
    let userdata = window.localStorage.getItem(configuration.localStorageKey);

    if (userdata === null || userdata === undefined)
      return {
        username: "?",
        role: "User",
      };

    let user = window.JSON.parse(userdata);

    if (user === null || user === undefined) {
      return {
        username: "?",
        role: "User",
      };
    }

    if (user.Roles.includes("Admin")) {
      return {
        username: user.UserName,
        role: "Admin",
      };
    }
    if (user.Roles.includes("SuperUser")) {
      return {
        username: user.UserName,
        role: "SuperUser",
      };
    }

    return {
      username: "?",
      role: "User",
    };
  };
  render() {
    //Setup global errorhandler, that catches globally unhandled exceptions
    //Setup barcodelistened, that sends read barcode to SelfService component as a prop
    return (
      <Router history={history}>
        <ErrorHandler>
          <Route
            path="/login"
            exact
            component={() => (
              <BarcodeListener>
                <BarcodeContext.Consumer>
                  {(code) => <Login barcode={code.rawBarcode} />}
                </BarcodeContext.Consumer>
              </BarcodeListener>
            )}
          />
          <Route
            path="/"
            exact
            component={() => (
              <BarcodeListener>
                <BarcodeContext.Consumer>
                  {(code) => <SelfService barcode={code.barcode} />}
                </BarcodeContext.Consumer>
              </BarcodeListener>
            )}
          />
          <Suspense fallback={<WaitingChunk />}>
            <Route
              path="/admin"
              exact
              component={() => {
                let nameRole = this.getUserNameAndRole();
                let adminAllowed =
                  nameRole.role === "Admin" || nameRole.role === "SuperUser";

                if (adminAllowed) {
                  return (
                    <Admin role={nameRole.role} username={nameRole.username} />
                  );
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />
          </Suspense>
        </ErrorHandler>
      </Router>
    );
  }
}

const WaitingChunk = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    }}
  >
    <div style={{ width: "200px", height: "100px", textAlign: "center" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="146.391"
        height="29.016"
        viewBox="0 0 146.391 29.016"
      >
        <path d="M104.572 19.636v-3.743c0-.403-.33-.696-.733-.696h-7.083c-.404 0-.734.293-.734.696v3.743c0 .404.33.697.734.697h7.082c.402 0 .732-.293.732-.697m-14.42 8.44v-16.22c0-8.988-4.696-10.676-12.108-10.676-1.578 0-5.98.33-7.853.623-1.687.257-2.237.698-2.237 2.68v23.593c0 .403.33.734.734.734h6.02c.4 0 .73-.33.73-.734V7.748c1.065-.147 2.35-.184 3.487-.184 2.46 0 3.743.808 3.743 4.293v16.22c0 .402.293.733.696.733h6.056c.402 0 .732-.33.732-.734m-29.536 0v-16.22c0-8.988-4.697-10.676-12.11-10.676-1.576 0-5.98.33-7.85.623-1.69.257-2.24.698-2.24 2.68v23.593c0 .403.33.734.734.734h6.018c.404 0 .734-.33.734-.734V7.748c1.064-.147 2.348-.184 3.486-.184 2.458 0 3.742.808 3.742 4.293v16.22c0 .402.294.733.698.733h6.054c.404 0 .734-.33.734-.734m-29.354-.073V8.223c0-.402-.404-.77-.808-.77h-5.467c-.403 0-.807.368-.807.77v19.78c0 .403.404.807.807.807h5.467c.403 0 .808-.404.808-.807m.184-23.3V1.105c0-.403-.404-.734-.808-.734H24.84c-.403 0-.843.33-.843.734v3.596c0 .404.44.734.844.734h5.798c.403 0 .808-.33.808-.734m-13.98 2.165V2.39c0-.44-.257-.66-.734-.734-1.394-.183-3.67-.44-7.23-.44C4.808 1.216.11 2.354.11 9.436v18.64c0 .403.33.734.734.734h5.98c.404 0 .735-.33.735-.734v-10.2h9.025c.404 0 .734-.33.734-.735v-4.328c0-.405-.33-.735-.735-.735H7.56v-2.64C7.56 8.004 8 7.6 9.32 7.6h7.412c.404 0 .734-.33.734-.733" />
        <path
          fill="#D21242"
          d="M138.776 16.946c0 4.44-1.47 5.615-4.403 5.615-.478 0-1.834 0-2.092-.036V7.37c.26 0 1.652-.037 2.093-.037 2.934 0 4.403 1.174 4.403 5.578v4.036zm7.523 0V12.91C146.3 4.655 141.64.95 134.372.95c-3.523 0-6.495.294-8.402.624-.806.147-1.174.44-1.174 1.43v23.852c0 .99.368 1.284 1.175 1.43 1.908.33 4.918.625 8.403.625 7.302 0 11.926-3.707 11.926-11.964M117.64 27.77V7.995c0-.404-.404-.77-.808-.77h-5.468c-.402 0-.807.366-.807.77V27.77c0 .405.403.808.806.808h5.468c.403 0 .808-.403.808-.807m.182-23.3V.876c0-.403-.403-.734-.807-.734h-5.798c-.405 0-.844.332-.844.735V4.47c0 .405.438.735.843.735h5.797c.404 0 .807-.33.807-.734"
        />
      </svg>
      Ladataan...
    </div>
  </div>
);

export default Routes;
