import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import PropTypes from "prop-types";
import kela from "../images/kelakortti.png";
import ajo from "../images/ajokortti.png";
import { Button, Icon } from "semantic-ui-react";
import packageJson from "../../package.json";

class Welcome extends Component {
  render() {
    const {
      languages,
      keyboard,
      inputPatientId,
      reset,
      frontpageFunction,
      clientIp,
    } = this.props;
    return (
      <React.Fragment>
        <Grid className="welcome-grid">
          <Grid.Row verticalAlign="middle">
            <Grid.Column width="16" textAlign="center">
              {frontpageFunction && (
                <div className="selected-function-text">
                  {languages &&
                    languages != null &&
                    languages[frontpageFunction.text]}
                </div>
              )}
              <div className="welcome">
                {languages && languages != null && languages.WELCOME_TEXT}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            verticalAlign="middle"
            className={keyboard ? "welcome-id-card-align" : ""}
          >
            <Grid.Column width="8" textAlign="center">
              <img src={kela} alt="kelakortti" />
            </Grid.Column>
            <Grid.Column width="8" textAlign="center">
              <img src={ajo} alt="ajokortti" />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {keyboard && (
          <div className="id-card-button">
            <Button
              icon
              labelPosition="left"
              color="blue"
              size="massive"
              onClick={inputPatientId}
            >
              <Icon name="keyboard" />
              {languages &&
                languages != null &&
                languages.INPUT_PATIENT_ID_TEXT}
            </Button>
            {reset && (
              <Button
                icon
                labelPosition="left"
                color="green"
                size="massive"
                onClick={reset}
              >
                <Icon name="step backward" />
                {languages ? languages.BACK_TO_START : "Takaisin"}
              </Button>
            )}
          </div>
        )}

        <div
          style={{
            position: "absolute",
            left: "5px",
            bottom: "5px",
            fontSize: "15px",
          }}
        >
          [ v{packageJson.version}
          {clientIp ? ` ${clientIp}` : ""}]
        </div>
      </React.Fragment>
    );
  }
}
Welcome.propTypes = {
  keyboard: PropTypes.any,
  languages: PropTypes.object,
  inputPatientId: PropTypes.func,
  reset: PropTypes.func,
  frontpageFunction: PropTypes.object,
  clientIp: PropTypes.string,
};
export default Welcome;
