import React, { Component } from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import logo from "../images/finnidlogo.svg";
import ApiCall from "./../api/ApiCall";
import { Redirect } from "react-router-dom";
import LoginForm from "../components/LoginForm";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: "",
      toUrl: "/login",
    };
  }
  /**
   * When component state or props updates
   * Check if current props barcode is different from previous props barcode
   *  => if true, then barcode is read.
   * @param {React previous properties} prevProps
   * @param {React previous state} prevState
   * @param {*} snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Jos tuli uusi viivakoodi
    if (this.props.barcode !== prevProps.barcode && this.props.barcode !== "") {
      this.loginByBarcode(this.props.barcode);
    }
  }
  /**
   * Special login method, where barcode contains username and password separated by #
   * @param {*} barcode
   * @returns
   */
  loginByBarcode = (barcode) => {
    //Username and password should be in barcode separated by #

    let unameTextBox = document.getElementById("uname");

    const separatorChar = "#";
    //Barcode contains username and password separated by separatorChar, split them into variables
    //If separatorChar is not found, then barcode is not valid
    if (barcode.indexOf(separatorChar) === -1) {
      console.log("Invalid barcode or user typed it");
      return;
    }

    //Cut barcode into username and password by first found separatorChar, there could exist multiple separatorChar, left side is username and right side is password
    // Find the index of the first separatorChar
    let hashIndex = barcode.indexOf(separatorChar);

    // Extract the username (before the separatorChar)
    let username = barcode.substring(0, hashIndex);

    // Extract the password (after the separatorChar)
    let password = barcode.substring(hashIndex + 1);

    //Insert into login form normally, not using react

    unameTextBox.value = username;
    let pwdTextBox = document.getElementById("pwd");
    pwdTextBox.value = password;
    let clientIpTextBox = document.getElementById("clientip");
    let clientIp = clientIpTextBox.value;

    //Then submit login with same credentials
    this.submitLogin(null, username, password, clientIp);
  };

  submitLogin = async (e, username, password, clientIp) => {
    this.setState({ loading: true, error: "" });
    let target = null;
    if (e) target = e.target;
    let result = await new ApiCall(true).login(username, password, clientIp);
    if (typeof result === "string") {
      console.error(result);
      this.setState({
        error: "Sisäänkirjautuminen epäonnistui",
        loading: false,
      });
    } else {
      await this.storeCredentials(target);
      let toUrl =
        result &&
        result.Roles &&
        (result.Roles.includes("Admin") || result.Roles.includes("SuperUser"))
          ? "/admin"
          : "/";
      this.setState({ loading: false, error: "", toUrl: toUrl });
    }
  };
  /**
   * Use browser credentials store to store credentials
   * @param {*} e
   * @returns
   */
  storeCredentials = async (target) => {
    try {
      if (
        window.PasswordCredential &&
        window.PublicKeyCredential &&
        target !== null
      ) {
        var c = await navigator.credentials.create({ password: target });
        return await navigator.credentials.store(c);
      }
    } catch (err) {
      console.log("PasswordCredential error", err);
    }
  };

  render() {
    const { loading, error, toUrl } = this.state;

    let innerHeight = window.innerHeight - 50;
    return toUrl !== "/login" ? (
      <Redirect to={toUrl} />
    ) : (
      <div style={{ height: innerHeight + "px" }}>
        <Image
          src={logo}
          style={{ position: "absolute", right: "10px", bottom: "10px" }}
        />
        <Grid
          textAlign="center"
          style={{ height: "inherit" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <LoginForm
              login={this.submitLogin}
              loading={loading}
              error={error}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
export default Login;
