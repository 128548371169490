import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react'
import Clock from './Clock';
import Language from './Language';

class PageHeader extends Component {

    render() {
        
        return (
            <Grid className='page-header'>
                <Grid.Row>
                    <Grid.Column width="3">
                        {this.props.logourl && <img src={this.props.logourl} alt="logo" className='logo' />}
                    </Grid.Column>
                    <Grid.Column width="10" textAlign="center">
                        <Clock />
                    </Grid.Column>
                    <Grid.Column width="3" textAlign="right">
                        <Language changelanguage={this.props.changelanguage} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );

    }
}
PageHeader.propTypes = {
    logourl: PropTypes.string,
    changelanguage: PropTypes.func.isRequired
}
export default PageHeader;