import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "semantic-ui-react";

class InputPatientId extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout: false,
    };
    this.inputPatientDay = React.createRef();
    this.inputPatientMonth = React.createRef();
    this.inputPatientYear = React.createRef();
    this.inputPatientMiddleChar = React.createRef();
    this.inputPatientEnd = React.createRef();
    this.focusInput = null;
    this.acceptButton = React.createRef();
    this.endCharTable = {
      0: "0",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "A",
      11: "B",
      12: "C",
      13: "D",
      14: "E",
      15: "F",
      16: "H",
      17: "J",
      18: "K",
      19: "L",
      20: "M",
      21: "N",
      22: "P",
      23: "R",
      24: "S",
      25: "T",
      26: "U",
      27: "V",
      28: "W",
      29: "X",
      30: "Y",
    };
  }

  componentDidMount() {
    this.inputPatientDay.current.inputRef.current.focus();
    this.focusInput = this.inputPatientDay.current.inputRef.current;
    this.acceptButton.current.ref.current.disabled = true;
    this.acceptButton.current.ref.current.className += " disabled";
    this.hideKeyboard();
  }
  /**
   * Hide keyboard
   */
  hideKeyboard = () => {
    //Hide virtual keyboard
    if (navigator && navigator.virtualKeyboard) {
      navigator.virtualKeyboard.hide();
    }
  };
  isNotValidMiddleChar = (stringValue) => {
    return (
      stringValue.replace("—", "-") !== "-" &&
      stringValue !== "A" &&
      stringValue !== "B" &&
      stringValue !== "C" &&
      stringValue !== "D" &&
      stringValue !== "E" &&
      stringValue !== "F" &&
      stringValue !== "Y" &&
      stringValue !== "X" &&
      stringValue !== "W" &&
      stringValue !== "V" &&
      stringValue !== "U"
    );
  };
  add = (e) => {
    if (this.focusInput.type === "number" && window.isNaN(e.target.innerText))
      return;
    else if (
      //Focused field is middlechar, check if not valid
      this.focusInput ===
        this.inputPatientMiddleChar.current.inputRef.current &&
      this.isNotValidMiddleChar(e.target.innerText)
    )
      return;

    let newValue = this.focusInput.value + e.target.innerText;
    if (
      this.focusInput.maxLength &&
      this.focusInput.maxLength <= newValue.length
    ) {
      this.focusInput.value =
        newValue.slice(0, this.focusInput.maxLength - 1) +
        newValue.slice(newValue.length - 1, newValue.length).replace("—", "-");
      this.focusNext();
    } else {
      this.focusInput.value = newValue.replace("—", "-");
    }
    //Automatically add endchar
    if (
      this.inputPatientEnd.current.inputRef.current.value.length === 3 &&
      this.focusInput === this.inputPatientEnd.current.inputRef.current
    )
      this.focusInput.value += this.getEndCalculatedEndChar() || "";

    this.enableDisableAcceptButton();
  };
  enableDisableAcceptButton = () => {
    this.acceptButton.current.ref.current.disabled = true;
    this.acceptButton.current.ref.current.className += " disabled";
    let validateResult = this.validate();
    this.setFocusHighlight();
    if (validateResult.valid === true) {
      this.acceptButton.current.ref.current.disabled = false;
      this.acceptButton.current.ref.current.className =
        this.acceptButton.current.ref.current.className.replace(
          /\bdisabled\b/g,
          ""
        );
    }
  };
  focusNext = () => {
    switch (this.focusInput) {
      case this.inputPatientDay.current.inputRef.current:
        this.inputPatientMonth.current.inputRef.current.focus();
        this.focusInput = this.inputPatientMonth.current.inputRef.current;
        this.focusInput.value = "";
        break;
      case this.inputPatientMonth.current.inputRef.current:
        this.inputPatientYear.current.inputRef.current.focus();
        this.focusInput = this.inputPatientYear.current.inputRef.current;
        this.focusInput.value = "";
        break;
      case this.inputPatientYear.current.inputRef.current:
        this.inputPatientMiddleChar.current.inputRef.current.focus();
        this.focusInput = this.inputPatientMiddleChar.current.inputRef.current;
        this.focusInput.value = "";
        break;
      case this.inputPatientMiddleChar.current.inputRef.current:
        this.inputPatientEnd.current.inputRef.current.focus();
        this.focusInput = this.inputPatientEnd.current.inputRef.current;
        this.focusInput.value = "";
        break;
      default:
        break;
    }
    this.hideKeyboard();
  };
  focusPrevious = () => {
    switch (this.focusInput) {
      case this.inputPatientDay.current.inputRef.current:
        break;
      case this.inputPatientMonth.current.inputRef.current:
        this.inputPatientDay.current.inputRef.current.focus();
        this.focusInput = this.inputPatientDay.current.inputRef.current;
        this.focusInput.value = "";
        break;
      case this.inputPatientYear.current.inputRef.current:
        this.inputPatientMonth.current.inputRef.current.focus();
        this.focusInput = this.inputPatientMonth.current.inputRef.current;
        this.focusInput.value = "";
        break;
      case this.inputPatientMiddleChar.current.inputRef.current:
        this.inputPatientYear.current.inputRef.current.focus();
        this.focusInput = this.inputPatientYear.current.inputRef.current;
        this.focusInput.value = "";
        break;
      case this.inputPatientEnd.current.inputRef.current:
        this.inputPatientMiddleChar.current.inputRef.current.focus();
        this.focusInput = this.inputPatientMiddleChar.current.inputRef.current;
        this.focusInput.value = "";
        break;
      default:
        break;
    }
    this.hideKeyboard();
  };
  validate = () => {
    //there should be a number in day, day should be 2 chars, number should not be over 31
    if (
      this.inputPatientDay.current.inputRef.current.value.length !== 2 ||
      window.isNaN(this.inputPatientDay.current.inputRef.current.value) ||
      window.parseInt(this.inputPatientDay.current.inputRef.current.value, 10) >
        31 ||
      window.parseInt(this.inputPatientDay.current.inputRef.current.value, 10) <
        1
    ) {
      this.inputPatientDay.current.inputRef.current.style.boxShadow =
        "0px 0px 14px 5px red";
      return {
        valid: false,
        text: "day",
        target: this.inputPatientDay.current.inputRef.current,
      };
    } else {
      this.inputPatientDay.current.inputRef.current.style.boxShadow = "";
    }
    //there should be a number in month, month should be 2 chars, number should not be over 12
    if (
      this.inputPatientMonth.current.inputRef.current.value.length !== 2 ||
      window.isNaN(this.inputPatientMonth.current.inputRef.current.value) ||
      window.parseInt(
        this.inputPatientMonth.current.inputRef.current.value,
        10
      ) > 12
    ) {
      this.inputPatientMonth.current.inputRef.current.style.boxShadow =
        "0px 0px 14px 5px red";
      return {
        valid: false,
        text: "month",
        target: this.inputPatientMonth.current.inputRef.current,
      };
    } else {
      this.inputPatientMonth.current.inputRef.current.style.boxShadow = "";
    }
    //there should be a number in year, year should be 2 chars
    if (
      this.inputPatientYear.current.inputRef.current.value.length !== 2 ||
      window.isNaN(this.inputPatientYear.current.inputRef.current.value)
    ) {
      this.inputPatientYear.current.inputRef.current.style.boxShadow =
        "0px 0px 14px 5px red";
      return {
        valid: false,
        text: "year",
        target: this.inputPatientYear.current.inputRef.current,
      };
    } else {
      this.inputPatientYear.current.inputRef.current.style.boxShadow = "";
    }
    //there should be a - or A or X, Y, ... in middlechar
    if (
      this.isNotValidMiddleChar(
        this.inputPatientMiddleChar.current.inputRef.current.value
      )
    ) {
      this.inputPatientMiddleChar.current.inputRef.current.style.boxShadow =
        "0px 0px 14px 5px red";
      return {
        valid: false,
        text: "middle",
        target: this.inputPatientMiddleChar.current.inputRef.current,
      };
    } else {
      this.inputPatientMiddleChar.current.inputRef.current.style.boxShadow = "";
    }
    //there should be 4 chars in ending
    //Calculate endChare and it should match
    let enteredValue = this.getEnteredValue();
    let calculatedEndChar = this.getEndCalculatedEndChar();

    if (
      this.inputPatientEnd.current.inputRef.current.value.length !== 4 ||
      (calculatedEndChar !== undefined &&
        this.inputPatientEnd.current.inputRef.current.value.endsWith(
          calculatedEndChar
        ) === false)
    ) {
      this.inputPatientEnd.current.inputRef.current.style.boxShadow =
        "0px 0px 14px 5px red";
      return {
        valid: false,
        text: "end",
        target: this.inputPatientEnd.current.inputRef.current,
      };
    } else {
      this.inputPatientEnd.current.inputRef.current.style.boxShadow = "";
    }

    //Hetu should be 11 chars
    if (enteredValue.length < 11) {
      return { valid: false, text: "all", target: null };
    }

    return { valid: true, text: "all", target: null };
  };
  getEndCalculatedEndChar = () => {
    let enteredValue = this.getEnteredValue();
    let startNumber = window.parseFloat(
      enteredValue.replace("-", "").replace("A", "").substr(0, 9),
      10
    );
    let endCheckIndex = Math.round(
      (startNumber / 31 - Math.floor(startNumber / 31)) * 31
    ); // 0 - 30
    /*console.log(enteredValue, "start:" + startNumber, "start / 31 = " + (startNumber / 31) + " - " + Math.floor(startNumber / 31) + 
                                    " = " + ((startNumber / 31) - (Math.floor(startNumber / 31))) + " * 31  = " + (((startNumber / 31) - (Math.floor(startNumber / 31))) * 31) + " ~ and rounded = " + endCheckIndex, " => " + calculatedEndChar);
        */
    return this.endCharTable[endCheckIndex];
  };
  remove = (e) => {
    if (
      this.focusInput &&
      this.focusInput !== null &&
      this.focusInput.value !== ""
    ) {
      this.focusInput.value = this.focusInput.value.slice(0, -1);
    } else {
      this.focusPrevious();
    }
    this.enableDisableAcceptButton();
  };
  getEnteredValue = () => {
    return (
      this.inputPatientDay.current.inputRef.current.value +
      this.inputPatientMonth.current.inputRef.current.value +
      this.inputPatientYear.current.inputRef.current.value +
      this.inputPatientMiddleChar.current.inputRef.current.value +
      this.inputPatientEnd.current.inputRef.current.value
    );
  };
  resetInputs = () => {
    this.inputPatientDay.current.inputRef.current.value = "";
    this.inputPatientMonth.current.inputRef.current.value = "";
    this.inputPatientYear.current.inputRef.current.value = "";
    this.inputPatientMiddleChar.current.inputRef.current.value = "";
    this.inputPatientEnd.current.inputRef.current.value = "";
  };
  setFocusHighlight = () => {
    if (
      this.inputPatientDay.current.inputRef.current.style.boxShadow.indexOf(
        "red"
      ) < 0
    )
      this.inputPatientDay.current.inputRef.current.style.boxShadow = "";
    if (
      this.inputPatientMonth.current.inputRef.current.style.boxShadow.indexOf(
        "red"
      ) < 0
    )
      this.inputPatientMonth.current.inputRef.current.style.boxShadow = "";
    if (
      this.inputPatientYear.current.inputRef.current.style.boxShadow.indexOf(
        "red"
      ) < 0
    )
      this.inputPatientYear.current.inputRef.current.style.boxShadow = "";
    if (
      this.inputPatientMiddleChar.current.inputRef.current.style.boxShadow.indexOf(
        "red"
      ) < 0
    )
      this.inputPatientMiddleChar.current.inputRef.current.style.boxShadow = "";
    if (
      this.inputPatientEnd.current.inputRef.current.style.boxShadow.indexOf(
        "red"
      ) < 0
    )
      this.inputPatientEnd.current.inputRef.current.style.boxShadow = "";

    if (
      this.focusInput &&
      this.focusInput !== null &&
      (this.focusInput !== this.inputPatientEnd.current.inputRef.current ||
        (this.focusInput === this.inputPatientEnd.current.inputRef.current &&
          this.inputPatientEnd.current.inputRef.current.value.length < 4))
    )
      this.focusInput.style.boxShadow = "0px 0px 14px 5px #2185d0";
  };
  accept = (e) => {
    const { onpatientid } = this.props;
    let enteredValue = this.getEnteredValue();

    if (this.validate().valid === true) {
      onpatientid(enteredValue);
      //Reset
      this.resetInputs();
    } else {
      this.setFocusHighlight();
    }
  };
  setFocus = (e) => {
    this.focusInput = e.target;
    this.focusInput.value = "";
    this.validate();
    this.setFocusHighlight();
    this.hideKeyboard();
    this.enableDisableAcceptButton();
  };
  render() {
    const { languages, reset } = this.props;

    let uiLanguages = languages === undefined ? {} : languages;
    return (
      <React.Fragment>
        <div className="input-patientid">
          <div className="keyboard-title">{uiLanguages.INPUT_PATIENT_ID}</div>
          <div className="keyboard-inputs">
            <form autoComplete="off" style={{ display: "inline" }}>
              <input
                autoComplete="off"
                name="hidden"
                type="text"
                style={{ display: "none" }}
              />

              <Input
                size="massive"
                className="patientid-day-textbox"
                ref={this.inputPatientDay}
                autoComplete="off"
                type="number"
                maxLength="2"
                onFocus={this.setFocus}
                placeholder="PP"
              />
              <Input
                size="massive"
                className="patientid-month-textbox"
                ref={this.inputPatientMonth}
                autoComplete="off"
                type="number"
                maxLength="2"
                onFocus={this.setFocus}
                placeholder="KK"
              />
              <Input
                size="massive"
                className="patientid-year-textbox"
                ref={this.inputPatientYear}
                autoComplete="off"
                type="number"
                maxLength="2"
                onFocus={this.setFocus}
                placeholder="VV"
              />
              <Input
                size="massive"
                className="patientid-middlechar-textbox"
                ref={this.inputPatientMiddleChar}
                autoComplete="off"
                maxLength="1"
                onFocus={this.setFocus}
                placeholder="-/A..."
              />
              <Input
                size="massive"
                className="patientid-end-textbox"
                ref={this.inputPatientEnd}
                autoComplete="off"
                maxLength="4"
                onFocus={this.setFocus}
                placeholder="XXXY"
              />
            </form>

            <Button
              size="massive"
              color="blue"
              onClick={this.accept}
              ref={this.acceptButton}
            >
              {uiLanguages.ACCEPT}
            </Button>

            <Button
              size="massive"
              onClick={() => {
                this.resetInputs();
                reset();
              }}
            >
              {uiLanguages.BACK_TO_START}
            </Button>
          </div>

          <div className="keyboard">
            <div className="keyboard-numbers">
              <div className="keyboard-row">
                <Button size="massive" onClick={this.add}>
                  7
                </Button>
                <Button size="massive" onClick={this.add}>
                  8
                </Button>
                <Button size="massive" onClick={this.add}>
                  9
                </Button>
              </div>
              <div className="keyboard-row">
                <Button size="massive" onClick={this.add}>
                  4
                </Button>
                <Button size="massive" onClick={this.add}>
                  5
                </Button>
                <Button size="massive" onClick={this.add}>
                  6
                </Button>
              </div>
              <div className="keyboard-row">
                <Button size="massive" onClick={this.add}>
                  1
                </Button>
                <Button size="massive" onClick={this.add}>
                  2
                </Button>
                <Button size="massive" onClick={this.add}>
                  3
                </Button>
              </div>
              <div className="keyboard-row left">
                <Button size="massive" disabled>
                  0
                </Button>
                <Button size="massive" onClick={this.add}>
                  0
                </Button>
                <Button size="massive" disabled>
                  0
                </Button>
              </div>
            </div>
            <div className="keyboard-ent-buttons">
              <div className="keyboard-row">
                <Button
                  size="massive"
                  className="keyboard-backspace-button"
                  style={{
                    background: "rgba(27,28,29,0.7)",
                    color: "#ffffff",
                    fontWeight: "bold",
                  }}
                  onClick={this.remove}
                >
                  ←
                </Button>
              </div>
              <div className="keyboard-row">
                <Button
                  size="massive"
                  className="keyboard-enter-button"
                  color="blue"
                  disabled
                  onClick={this.accept}
                ></Button>
              </div>
            </div>
            <div className="keyboard-row">
              <Button size="massive" onClick={this.add}>
                Q
              </Button>
              <Button size="massive" onClick={this.add}>
                W
              </Button>
              <Button size="massive" onClick={this.add}>
                E
              </Button>
              <Button size="massive" onClick={this.add}>
                R
              </Button>
              <Button size="massive" onClick={this.add}>
                T
              </Button>
              <Button size="massive" onClick={this.add}>
                Y
              </Button>
              <Button size="massive" onClick={this.add}>
                U
              </Button>
              <Button size="massive" onClick={this.add}>
                I
              </Button>
              <Button size="massive" onClick={this.add}>
                O
              </Button>
              <Button size="massive" onClick={this.add}>
                P
              </Button>
              <Button size="massive" onClick={this.add}>
                Å
              </Button>
            </div>
            <div className="keyboard-row right">
              <Button size="massive" onClick={this.add}>
                A
              </Button>
              <Button size="massive" onClick={this.add}>
                S
              </Button>
              <Button size="massive" onClick={this.add}>
                D
              </Button>
              <Button size="massive" onClick={this.add}>
                F
              </Button>
              <Button size="massive" onClick={this.add}>
                G
              </Button>
              <Button size="massive" onClick={this.add}>
                H
              </Button>
              <Button size="massive" onClick={this.add}>
                J
              </Button>
              <Button size="massive" onClick={this.add}>
                K
              </Button>
              <Button size="massive" onClick={this.add}>
                L
              </Button>
              <Button size="massive" onClick={this.add}>
                Ö
              </Button>
              <Button size="massive" onClick={this.add}>
                Ä
              </Button>
            </div>
            <div className="keyboard-row">
              <Button size="massive" onClick={this.add}>
                Z
              </Button>
              <Button size="massive" onClick={this.add}>
                X
              </Button>
              <Button size="massive" onClick={this.add}>
                C
              </Button>
              <Button size="massive" onClick={this.add}>
                V
              </Button>
              <Button size="massive" onClick={this.add}>
                B
              </Button>
              <Button size="massive" onClick={this.add}>
                N
              </Button>
              <Button size="massive" onClick={this.add}>
                M
              </Button>
              <Button size="massive" onClick={this.add} disabled>
                ,
              </Button>
              <Button size="massive" onClick={this.add} disabled>
                .
              </Button>
              <Button
                size="massive"
                style={{ background: "rgba(125,125,125,1)", color: "white" }}
                onClick={this.add}
              >
                &mdash;
              </Button>
              <Button size="massive" onClick={this.add} disabled>
                _
              </Button>
            </div>
            <div className="keyboard-row">
              <Button
                size="massive"
                className="keyboard-space"
                onClick={this.add}
                disabled
              >
                &nbsp;
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
InputPatientId.propTypes = {
  onpatientid: PropTypes.func.isRequired,
  languages: PropTypes.object,
  reset: PropTypes.func,
};
export default InputPatientId;
