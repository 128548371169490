import React, { Component } from "react";
import PropTypes from "prop-types";
import PageHeader from "../components/PageHeader";
import ApiCall from "./../api/ApiCall";
import Welcome from "../components/Welcome";
import Info from "../components/Info";
import Success from "../components/Success";
import configuration from "./../configuration";
import moment from "moment";
import MessagePage from "../components/MessagePage";
import { SelfServiceViews } from "./../helpers/SelfServiceViews";
import { LogError, LogInformation } from "../helpers/Log";
import shortid from "shortid";
import InputPatientId from "./../components/InputPatientId";
import AppointmentLocalStatus from "./../helpers/AppointmentLocalStatus";
import NewAppointment from "../components/NewAppointment";
import SelectFrontPage from "../components/SelectFrontPage";

class SelfService extends Component {
  constructor(props) {
    super(props);

    this.timeout = null; //TimeoutId for ui automatic change
    this.backgroundinterval = null; //IntervalId to try to refresh settings and languages in the background
    this.nightRefreshTimeout = null; //Timeout to refresh the page
    this.state = {
      patients: [],
      appointments: [],
      registration: [], //List of schedule-events
      languages: { fi: {}, en: {} },
      settings: {},
      progress: SelfServiceViews.start,
      lang: "fi",
      error: {},
      css: "", //Custom css styles
      scheduledRefresh: false, //If true, then page will be refreshed once on welcome screen
      sessionid: shortid.generate(), //Unique identifier for any patient request session.
      selfServiceEventId: null, //Selfservice Event id for full patient checkin event, server generates it and then it is passed along
      frontpageFunction: null, //If using separate frontpage, user can select function, it is stored here
    };
  }
  /**
   * When component state or props updates
   * Check if current props barcode is different from previous props barcode
   *  => if true, then barcode is read.
   *      => if we are in start, welcome view, then start the appointment registration by getting data from rest
   * @param {React previous properties} prevProps
   * @param {React previous state} prevState
   * @param {*} snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Jos tuli uusi viivakoodi
    if (this.props.barcode !== prevProps.barcode && this.props.barcode !== "") {
      //Jos ollaan aloitusnäkymässä
      if (this.state.progress === SelfServiceViews.start) {
        //Hae uusi schedule viivakoodilla = hetu
        this.getScheduleAndAutoregister(this.props.barcode);
      }
    }
  }
  /**
   * React Component is ready
   * - Clear any existing timeouts and intervals
   * - Get settings and languages
   * - Schedule a full page refresh
   * - Setup interval to query settings and languages periodically
   */
  componentDidMount() {
    this.clearIntervals();
    this.clearTimeouts();

    this.getSettings();
    this.getLanguages();
    this.scheduleNightRefresh();
    this.definePrinterErrorFunction();
    //Setting minimum of 10 sec
    let retryTimeout = this.getSafeTimeoutSetting(
      "BACKGROUND_RETRY_TIMEOUT",
      10000
    );

    //Refresh settings and languages in the background, with intervals
    this.backgroundinterval = window.setInterval(() => {
      this.getSettings();
      this.getLanguages();
    }, retryTimeout); //From settings or if no settings, then value from here
  }
  /**
   * User leaves selfservice page
   * - Clear timeouts and intervals
   */
  componentWillUnmount() {
    this.clearIntervals();
    this.clearTimeouts();
    //Clear night refresh
    if (this.nightRefreshTimeout && this.nightRefreshTimeout !== null) {
      window.clearTimeout(this.nightRefreshTimeout);
      this.nightRefreshTimeout = null;
    }
  }
  /**
   * Print appointment
   * @param {*} appointment
   */
  print = (appointment) => {
    if (window.Android && window.Android.connectPrinter) {
      const formattedDate = moment(appointment.StartDateTime)
        .utc()
        .format("LLL");

      let langKey = "Finnish";
      if (this.state.lang === "fi") langKey = "Finnish";
      else if (this.state.lang === "se") langKey = "Swedish";
      else if (this.state.lang === "en") langKey = "English";
      else if (this.state.lang === "ru") langKey = "Russian";

      let locationString = appointment.Locations.join(", ");

      window.Android.connectPrinter(
        this.state.selfServiceEventId, //queue number
        formattedDate, //starting time
        appointment.Description, //why are we here?
        locationString, //room
        appointment.Directions[langKey] //guide
      );
      //https://ilmo.attune.fi/files/api/v1/File?filename=test.jpg
    }
  };
  /**
   * Call printer error
   */
  printerError = (message) => {
    window.attunePrinterError(message);
  };
  /**
   * Create global window attunePrinterError function
   */
  definePrinterErrorFunction = () => {
    //Android app calls this when an error occures
    window.attunePrinterError = (message) => {
      //TODO: Näytölle varoitus, jossa message on joko "kansi auki", "paperi loppu" tai "tulostin offline".
      alert(message);
    };
  };

  /**
   * Reset to start
   * - Clear any timeouts
   * - Clear data from state, and set progress back to start
   */
  reset = () => {
    this.clearTimeouts();
    this.setState(
      {
        patients: [],
        appointments: [],
        registration: [],
        progress: this.hasSelectFrontPageEnabled()
          ? SelfServiceViews.selectfrontpage
          : SelfServiceViews.start,
        selfServiceEventId: null, //Clear current eventId when moving to startscreen
        frontpageFunction: null,
      },
      () => {
        this.changelanguage("fi");
      }
    );
  };
  /***
   * Setup to refresh the page on on specific time
   */
  scheduleNightRefresh = () => {
    let isDebugMode = this.isDebugMode();

    if (this.nightRefreshTimeout && this.nightRefreshTimeout !== null) {
      window.clearTimeout(this.nightRefreshTimeout);
      this.nightRefreshTimeout = null;
    }

    let millisecondsUntilNight = this.getMillisecondsUntilNight();

    this.nightRefreshTimeout = window.setTimeout(async () => {
      let success = await this.connectedRefresh(); //Refresh page now, if failed then returns false
      if (success === false) {
        //Refresh failed, schedule next one
        this.scheduleNightRefresh();
      }
    }, millisecondsUntilNight);

    if (isDebugMode)
      LogInformation(
        "scheduleNightRefresh",
        "Scheduled a refresh at",
        moment()
          .add({ milliseconds: millisecondsUntilNight })
          .format("DD.MM.YYYY HH:mm:ss"),
        this.state.sessionid,
        null
      );
  };
  /***
   * Calculate milliseconds from now untill night
   */
  getMillisecondsUntilNight = () => {
    let now = moment();
    let night = now.clone().hour(7).minute(0).second(0).millisecond(0); //Same as now, but hour is 07:00
    if (now.isAfter(night)) {
      night = night.clone().add({ days: 1 }); //Same time, but tomorrow
    }
    return night.diff(now, "milliseconds");
  };
  /**
   * Check that there is a connection to REST
   * Then refresh page
   */
  connectedRefresh = async () => {
    let isDebugMode = this.isDebugMode();

    try {
      if (isDebugMode)
        LogInformation(
          "connectedRefresh",
          "Calling REST to see if connected",
          this.state.sessionid,
          null
        );

      //Call rest, just to check that there is a valid connection to server, and server is not down
      await new ApiCall().get(configuration.ROOT_URL + "/v1/Settings/client");

      if (isDebugMode)
        LogInformation(
          "connectedRefresh",
          "Connection to REST exists, Refreshing page",
          null
        );

      //Refresh
      window.location.reload(true);

      return true;
    } catch (e) {
      //Ignore error
      if (isDebugMode)
        LogInformation(
          "connectedRefresh",
          "Failed, no connection to REST api",
          this.state.sessionid,
          null
        );

      return false;
    }
  };
  /***
   * Get all ui languages
   */
  getLanguages = async () => {
    try {
      //Get all 0 - 999999
      let languages = await new ApiCall().get(
        configuration.ROOT_URL + "/v1/Language/client"
      );

      this.setState({ languages: languages });
    } catch (e) {
      let msg =
        "Virhe tietojen haussa, yritetään hakea uudestaan automaattisesti";
      let title = "Huom";

      //Only show errormessage if there exist any settings
      if (this.state.settings && window.Object.keys(this.state.settings) > 0) {
        if (
          this.state.languages &&
          window.Object.keys(this.state.languages) > 0
        ) {
          msg = this.state.languages[this.state.lang].ERROR_FETCHING_SETTINGS;
          title = this.state.languages[this.state.lang].NOTICE_TITLE;
        }
        this.handleErrorMessage(msg, title, "red");
      }
    }
  };
  /***
   * Get a timeout setting value
   * MAKE sure it is a number, and that it is over minimum
   */
  getSafeTimeoutSetting = (key, min) => {
    const { settings } = this.state;
    if (
      settings &&
      settings[key] &&
      window.isNaN(settings[key]) === false &&
      window.parseInt(settings[key], 10) > min
    ) {
      return window.parseInt(settings[key], 10);
    }
    return min;
  };
  /**
   * Set custom css styles
   * Append custom style tag to page with custom css content
   */
  setCssStyles = (css) => {
    let styles = document.getElementById("customstyles");
    if (styles) {
      document.head.removeChild(styles);
    }
    if (css) {
      //<link rel="stylesheet" type="text/css" href={css} />

      let customStyles = css
        .replace(/(?:\r\n|\r|\n)/g, "")
        .replace(/<(?:.|\n)*?>/gm, "");
      let stylesheet = document.createElement("style");
      stylesheet.type = "text/css";
      stylesheet.id = "customstyles";
      stylesheet.innerText = customStyles;

      document.head.appendChild(stylesheet);
    }
  };
  /***
   * Get all required settings async
   * - If debug is on, then log information
   * - When request completes, set new settings to state
   * - If settings.VERSION has changed,
   *      then scheduleRefresh => full page refresh, when in welcome view
   *      Update settings to state, and in callback use new settings
   * - If settings request fails, show message in ui
   */
  getSettings = async () => {
    try {
      let newsettings = await new ApiCall().get(
        configuration.ROOT_URL + "/v1/Settings/client"
      );

      let backgroundRetry = this.getSafeTimeoutSetting(
        "BACKGROUND_RETRY_TIMEOUT",
        10000
      );

      this.setState(
        (state, props) => {
          //If VERSION IS CHANGED, schedule full refresh
          if (
            state.settings &&
            state.settings.VERSION &&
            newsettings.VERSION &&
            state.settings.VERSION !== newsettings.VERSION
          ) {
            return { scheduledRefresh: true, settings: newsettings };
          }

          return { settings: newsettings };
        },
        () => {
          this.setCssStyles(newsettings.CSS);
          //Intervals might have changed, restart interval
          this.clearIntervals();

          this.backgroundinterval = window.setInterval(() => {
            this.getSettings();
            this.getLanguages();
          }, backgroundRetry);

          //
          //Frontpage is enabled and user is in start (Welcome) screen but no function is selected => move to selectfrontpage
          if (
            this.state.progress === SelfServiceViews.start &&
            this.state.frontpageFunction === null && //user has not selected a function
            this.hasSelectFrontPageEnabled()
          ) {
            this.setState({ progress: SelfServiceViews.selectfrontpage });
          }
          //
          //Frontpage is not enabled and user is in selectfrontpage view => move to start (Welcome)
          else if (
            this.state.progress === SelfServiceViews.selectfrontpage &&
            !this.hasSelectFrontPageEnabled()
          ) {
            this.setState({ progress: SelfServiceViews.start });
          }
        }
      );
    } catch (e) {
      let msg =
        "Virhe tietojen haussa, yritetään hakea uudestaan automaattisesti";
      let title = "Huom";

      if (
        this.state.languages &&
        window.Object.keys(this.state.languages) > 0
      ) {
        msg = this.state.languages[this.state.lang].ERROR_FETCHING_SETTINGS;
        title = this.state.languages[this.state.lang].NOTICE_TITLE;
      }
      this.handleErrorMessage(msg, title, "red");
    }
  };
  /****
   * Clear all and any timeouts
   */
  clearTimeouts = () => {
    if (this.timeout && this.timeout !== null) {
      window.clearTimeout(this.timeout);
      this.timeout = null;
    }
  };
  /****
   * Clear all and any intervals
   */
  clearIntervals() {
    if (this.backgroundinterval && this.backgroundinterval !== null)
      window.clearInterval(this.backgroundinterval);
  }
  /***
   * Is debugmode on
   */
  isDebugMode() {
    if (this.state.settings && this.state.settings.DEBUG) {
      return (
        this.state.settings.DEBUG.toLowerCase() === "true" ||
        this.state.settings.DEBUG.toLowerCase() === "1"
      );
    } else return false;
  }
  /**
   * User can create new appointments
   */
  canCreateAppointment = () => {
    if (this.state.settings && this.state.settings.CAN_CREATE_NEW_APPOINTMENT) {
      return (
        this.state.settings.CAN_CREATE_NEW_APPOINTMENT.toLowerCase() ===
          "true" ||
        this.state.settings.CAN_CREATE_NEW_APPOINTMENT.toLowerCase() === "1"
      );
    } else return false;
  };
  /**
   * User can register to multiple open appointments at one checkin session
   * @returns
   */
  canRegisterToMultipleAppointments = () => {
    if (
      this.state.settings &&
      this.state.settings.CAN_REGISTER_TO_MULTIPLE_APPOINTMENTS
    ) {
      return (
        this.state.settings.CAN_REGISTER_TO_MULTIPLE_APPOINTMENTS.toLowerCase() ===
          "true" ||
        this.state.settings.CAN_REGISTER_TO_MULTIPLE_APPOINTMENTS.toLowerCase() ===
          "1"
      );
    } else return false;
  };
  /**
   * Frontpage can have list of functionbuttons that come from settings
   * functions have text => key for languages and settings:{ } object that can contain overriding settings for appointments and registering
   */
  getSelectFrontPageFunctions = () => {
    if (this.state.settings && this.state.settings.SELECT_FRONTPAGE_FUNCTIONS) {
      const settingsArray = window.JSON.parse(
        this.state.settings.SELECT_FRONTPAGE_FUNCTIONS
      );
      return settingsArray;
    } else return [];
  };
  /**
   * If true, then startpage is appointment type select view
   * If false, then basic welcome screen, to read barcode from id card
   * @returns boolean
   */
  hasSelectFrontPageEnabled = () => {
    if (this.state.settings && this.state.settings.HAS_SELECT_FONTPAGE) {
      return (
        this.state.settings.HAS_SELECT_FONTPAGE.toLowerCase() === "true" ||
        this.state.settings.HAS_SELECT_FONTPAGE.toLowerCase() === "1"
      );
    } else return false;
  };
  /**
   * Get patient data and schedule, list of appointments by patientId = hetu.
   * When data arrives from server, automatically register to first appointment
   * Then schedule timeout to move user to success = page after timeout seconds
   * if there are any errors, show message view => schedule timeout to move to start
   *
   * @param patientId patients personal security code (hetu)
   * @param createNew if true, then creating new appointment. Call to create url to create and return new appointment
   */
  getScheduleAndAutoregister = async (patientId, createNew) => {
    try {
      this.clearTimeouts(); //Clear any timeouts if any
      let isDebugMode = this.isDebugMode();
      let newSessionId = shortid.generate();
      let selfServiceEventId = createNew ? this.state.selfServiceEventId : null; //reset eventId if not creating new
      //Move to info -view .. to wait for response.
      //Setup a new sessionid for every patientid request
      this.setState({
        progress: SelfServiceViews.info,
        sessionid: newSessionId,
        appointments: [],
        patients: [],
        registration: [],
        selfServiceEventId: selfServiceEventId, //reset eventId if not creating new
      });

      if (isDebugMode)
        LogInformation(
          "getScheduleAndAutoregister",
          "Getting schedule for patient",
          newSessionId,
          selfServiceEventId
        );

      let scheduleUrl = configuration.ROOT_URL + "/v1/Schedule";
      if (createNew && createNew === true) {
        if (isDebugMode)
          LogInformation(
            "getScheduleAndAutoregister",
            "Creating new appointment",
            newSessionId,
            selfServiceEventId
          );
        scheduleUrl += "/create";
      }

      let schedule = await new ApiCall().post(scheduleUrl, {
        PatientID: patientId,
        SelfServiceEventId:
          selfServiceEventId === null ? 0 : selfServiceEventId,
        Settings: this.state.frontpageFunction
          ? this.state.frontpageFunction.settings
          : {},
      });

      if (isDebugMode)
        LogInformation(
          "getScheduleAndAutoregister",
          "Getting schedule for patient - DONE",
          newSessionId,
          schedule ? schedule.selfServiceEventId : selfServiceEventId
        );

      //If everything is ok, data is ok
      if (
        schedule &&
        schedule.appointments &&
        schedule.patients &&
        schedule.patients.length > 0 &&
        schedule.appointments.length > 0
      ) {
        //Set data, and do work after that
        this.setState(
          {
            appointments: schedule.appointments,
            patients: schedule.patients,
            progress: SelfServiceViews.info, //Infoview
            selfServiceEventId: schedule.selfServiceEventId, //Event id to send in requests
          },
          () => {
            /*
                1.	Potilaalla on ajanvaraus klo 12:00
                2.	Potilas ilmoittautuu klo 11:40 ajanvaraukseen.
                3.	Potilas ilmoittautuu/tarkastaa ajanvarauksen uudelleen klo 11:41
                4.	Potilaalle näytetään klo 12 ajanvarauksen tiedot, mihin hän jo ilmoittautui, eikä ilmoittauduta seuraavaan
                5.	Ajanvarauksen tiedot voi ”tarkastaa” ajanvarauksen alkuun asti, eli klo 12:00 asti 
                a.	Eli jos ilmoittautuu uudelleen / tarkastaa ajanvarauksen, se näyttäisi uudelleen ajanvarauksen tiedot.
                6.	Jos potilaalla on toinen ajanvaraus esim. klo 13:00, ja potilas ilmoittautuu / tarkastaa ajanvarauksen klo 12:00 jälkeen => ilmoittaudutaan klo 13:00 ajanvaraukseen.
                7.	Jos potilaalla on vain 1 ajanvaraus päivälle, sitä esitetään aina uudestaan, vaikka ilmoittautuu/tarkastaa klo 12:00 jälkeenkin.
                */
            let patient = schedule.patients[0];
            let now = moment().utc();
            let foundValidAppointment = false;
            let minutesToPast = 15; //If StartTime is in the past, still show appointment if less than [minutesToPast] -minutes ago
            let canRegisterToMultipleAppointments =
              this.canRegisterToMultipleAppointments();
            let multipleAppointments = []; //will contain list of open appointments to register to if canRegisterToMultipleAppointments = true
            //
            // Go through all appointments
            //

            for (let ap = 0; ap < schedule.appointments.length; ap++) {
              let nextAppointment = schedule.appointments[ap];

              //Cut off the timezone for date comparison
              let nextAppointmentStartDate = nextAppointment.StartDateTime
                ? moment(nextAppointment.StartDateTime).utc()
                : null;

              const isLastAppointment = ap === schedule.appointments.length - 1;
              const startTimeIsInFuture =
                nextAppointment.StartDateTime &&
                nextAppointmentStartDate !== null &&
                nextAppointmentStartDate > now;

              //Find out if there are open appointments in array after this one
              let thereExistsAnOpenAppointmentsAfterThisOne = false;
              for (
                let afterIndex = ap + 1;
                afterIndex < schedule.appointments.length;
                afterIndex++
              ) {
                thereExistsAnOpenAppointmentsAfterThisOne =
                  schedule.appointments[afterIndex].LocalStatus === undefined ||
                  schedule.appointments[afterIndex].LocalStatus ===
                    AppointmentLocalStatus.Open;

                if (thereExistsAnOpenAppointmentsAfterThisOne) break;
              }

              //If next appointment is in state Open, => checking / register
              //If not open, then only show details of appointment

              //Normal registering to first appointment
              if (
                canRegisterToMultipleAppointments === false &&
                (nextAppointment.LocalStatus === undefined ||
                  nextAppointment.LocalStatus === AppointmentLocalStatus.Open)
              ) {
                let validAppointments = [...schedule.appointments].slice(ap);

                if (isDebugMode)
                  LogInformation(
                    "getScheduleAndAutoregister",
                    "Can checking to one appointment, nextAppointment status is Open, checking in",
                    newSessionId,
                    schedule ? schedule.selfServiceEventId : selfServiceEventId
                  );

                this.setState({ appointments: validAppointments }, () => {
                  //Automatically register to first appointment available, and set timeout to move to success
                  this.sendRegistration(
                    patient.PatientId,
                    [
                      {
                        slotid: nextAppointment.SlotId,
                        implementation: nextAppointment.Implementation,
                      },
                    ],
                    [nextAppointment]
                  );
                });

                foundValidAppointment = true;
                break; //STOP processing appointments here
              }
              //Can register to multiple appointments
              else if (
                canRegisterToMultipleAppointments === true &&
                (nextAppointment.LocalStatus === undefined ||
                  nextAppointment.LocalStatus === AppointmentLocalStatus.Open)
              ) {
                if (isDebugMode)
                  LogInformation(
                    "getScheduleAndAutoregister",
                    "Can check in to multiple appointments, status is Open, adding to list of appointments for checkin.",
                    newSessionId,
                    schedule ? schedule.selfServiceEventId : selfServiceEventId
                  );

                multipleAppointments.push({ ...nextAppointment });
              }
              //If user can checkin to multiple and there are open appointments after this one
              else if (
                canRegisterToMultipleAppointments === true &&
                thereExistsAnOpenAppointmentsAfterThisOne
              ) {
                if (isDebugMode)
                  LogInformation(
                    "getScheduleAndAutoregister",
                    "Can check in to multiple appointments, This appointment is not Open, but there exists another open appointment after this one. Skipping this appointment.",
                    newSessionId,
                    schedule ? schedule.selfServiceEventId : selfServiceEventId
                  );
                //This appointment is not Open, but it is in the future, add to list to show in ui
                if (startTimeIsInFuture)
                  multipleAppointments.push({ ...nextAppointment });
              }
              //If starttime is in future show appointment
              else if (startTimeIsInFuture) {
                //Appointment is not open, and is in future
                if (isDebugMode)
                  LogInformation(
                    "getScheduleAndAutoregister",
                    "Appointment is not Open but in future time, showing details, no checkin.",
                    newSessionId,
                    schedule ? schedule.selfServiceEventId : selfServiceEventId
                  );
                this.showAppointment(ap);
                foundValidAppointment = true;
                break; //STOP processing appointments here
              }

              //If last appointment, and starttime is in the past, but under [minutesToPast] minutes ago (f.e. show last appointment 15 min after start)
              else if (
                isLastAppointment &&
                nextAppointmentStartDate !== null &&
                now > nextAppointmentStartDate &&
                moment
                  .duration(now.diff(nextAppointmentStartDate))
                  .asMinutes() <= minutesToPast
              ) {
                //Appointment is not open, and is in the past, but starttime is [minutesToPast] minutes old
                if (isDebugMode)
                  LogInformation(
                    "getScheduleAndAutoregister",
                    "Appointment is not Open but in past time, showing details, no checkin.",
                    newSessionId,
                    schedule ? schedule.selfServiceEventId : selfServiceEventId
                  );
                this.showAppointment(ap);
                foundValidAppointment = true;
                break;
              }
            }

            //If user can register to multiple appointments
            if (
              canRegisterToMultipleAppointments &&
              multipleAppointments.length > 0
            ) {
              //convert to registrationList
              if (isDebugMode)
                LogInformation(
                  "getScheduleAndAutoregister",
                  "Checking in to multiple appointments.",
                  newSessionId,
                  schedule ? schedule.selfServiceEventId : selfServiceEventId
                );

              let registerToMultiple = multipleAppointments
                //Send registration to only ones that are open (the list of multiple appointments can contain checkedIn appointments, if their start time is in the future)
                .filter(
                  (i) =>
                    i.LocalStatus === undefined ||
                    i.LocalStatus === AppointmentLocalStatus.Open
                )
                .map((appointment) => {
                  return {
                    slotid: appointment.SlotId,
                    implementation: appointment.Implementation,
                  };
                });

              let validAppointments = [...multipleAppointments];
              this.setState({ appointments: validAppointments }, () => {
                this.sendRegistration(
                  patient.PatientId,
                  registerToMultiple,
                  validAppointments
                );
              });

              foundValidAppointment = true;
            }

            if (foundValidAppointment === false) {
              //IF patient exists and If there is a setting, user can create new appointment if no appointments
              //Ask if user wants to create appointment
              if (
                schedule.patients &&
                schedule.patients.length > 0 &&
                this.canCreateAppointment()
              ) {
                if (isDebugMode)
                  LogInformation(
                    "getScheduleAndAutoregister",
                    "Not checked to any appointment, ask to create new = päivystys",
                    newSessionId,
                    schedule ? schedule.selfServiceEventId : selfServiceEventId
                  );

                this.askToCreateAppointment();
              } else {
                //There were appointments, but no valid ones
                //=> all appointments are in the past
                //Show last appointment details
                if (isDebugMode)
                  LogInformation(
                    "getScheduleAndAutoregister",
                    "Not checked to any appointment, show details of last appointment",
                    newSessionId,
                    schedule ? schedule.selfServiceEventId : selfServiceEventId
                  );
                this.showAppointment(schedule.appointments.length - 1);
              }
            }
          }
        );
      }
      //No appointments
      else if (
        schedule &&
        schedule.success === true &&
        schedule.appointments &&
        schedule.appointments.length === 0
      ) {
        //If there is a setting, user can create new appointment if no appointments
        //Ask if user wants to create appointment
        if (
          schedule.patients &&
          schedule.patients.length > 0 &&
          this.canCreateAppointment()
        ) {
          if (isDebugMode)
            LogInformation(
              "getScheduleAndAutoregister",
              "No appointments found, ask to create new = päivystys",
              newSessionId,
              schedule ? schedule.selfServiceEventId : selfServiceEventId
            );
          this.setState({ patients: schedule.patients }, () => {
            this.askToCreateAppointment();
          });
        } else {
          if (isDebugMode)
            LogInformation(
              "getScheduleAndAutoregister",
              "No appointments found",
              newSessionId,
              schedule ? schedule.selfServiceEventId : selfServiceEventId
            );

          let patientName = "";
          if (schedule.patients && schedule.patients.length > 0)
            patientName =
              schedule.patients[0].FirstNames +
              " " +
              schedule.patients[0].LastName;

          this.handleErrorMessage(
            this.state.languages[this.state.lang].NO_APPOINTMENTS_DATA_FOUND,
            this.state.languages[this.state.lang].NOTICE_TITLE,
            "blue",
            patientName
          );
        }
      } else if (schedule && schedule.success === false) {
        //Request was not successfull, it means attune works, but something failed forpatientsystems

        if (isDebugMode)
          LogInformation(
            "getScheduleAndAutoregister",
            "No appointments found, Patient system failed. Show fail message.",
            newSessionId,
            schedule ? schedule.selfServiceEventId : selfServiceEventId
          );

        this.handleErrorMessage(
          this.state.languages[this.state.lang].FAILED_PATIENT_SYSTEM_FAIL,
          this.state.languages[this.state.lang].NOTICE_TITLE,
          "red"
        );
      }
      //No data at all?
      else {
        if (isDebugMode)
          LogInformation(
            "getScheduleAndAutoregister",
            "No appointments found, No data. Show fail message.",
            newSessionId,
            schedule ? schedule.selfServiceEventId : selfServiceEventId
          );

        this.handleErrorMessage(
          this.state.languages[this.state.lang].NO_DATA_FOUND,
          this.state.languages[this.state.lang].NOTICE_TITLE,
          "blue"
        );
      }
    } catch (e) {
      LogInformation(
        "getScheduleAndAutoregister",
        "No appointments found, show fail message. Javascript Exception :" +
          JSON.stringify(e)
      );

      this.handleErrorMessage(
        this.state.languages[this.state.lang].FAILED_FETCHING_DATA,
        this.state.languages[this.state.lang].NOTICE_TITLE,
        "blue"
      );
    }
  };
  /**
   * Create new default appointment starting right now
   */
  newAppointment = (patientId) => {
    this.clearTimeouts();
    this.getScheduleAndAutoregister(patientId, true);
  };
  /******
   * Move ui to ask to create new appointment.
   * Ask if user wants to create new appointment "Ilmoittaudu päivystykseen"
   *      If yes, then create Appointment
   *      If no, go back to start
   *
   */
  askToCreateAppointment = () => {
    //Clear all timeouts, if anything is waiting
    this.clearTimeouts();
    let isDebugMode = this.isDebugMode();

    //Ask user if he/she wants to create new appointment
    this.setState(
      {
        error: {},
        progress: SelfServiceViews.newappointment, //Move ui to ask new appointment
      },
      () => {
        if (isDebugMode)
          LogInformation(
            "askToCreateAppointment",
            "Moved to New appointment view - DONE",
            this.state.sessionid,
            this.state.selfServiceEventId
          );

        let canCreateNewTimeout = this.getSafeTimeoutSetting(
          "CAN_CREATE_NEW_APPOINTMENT_TIMEOUT",
          0
        );

        //Set timeout to move back to start
        this.timeout = window.setTimeout(() => {
          this.setState(
            {
              progress: this.hasSelectFrontPageEnabled()
                ? SelfServiceViews.selectfrontpage
                : SelfServiceViews.start,
              patients: [],
              appointments: [],
              registration: [],
              selfServiceEventId: null, //Clear current eventId when moving to startscreen
              frontpageFunction: null,
            },
            () => {
              this.changelanguage("fi"); //Change language back to finnish
            }
          );
          if (isDebugMode)
            LogInformation(
              "askToCreateAppointment",
              "Moved to Beginning view - DONE",
              this.state.sessionid,
              this.state.selfServiceEventId
            );
        }, canCreateNewTimeout);
      }
    );
  };
  /****
   * Show selected appointment, jump to success and show details
   * Loop through appointments, remove older appointments than selected
   * Then move to success and setup timer to move back to welcome
   */
  showAppointment = (index) => {
    try {
      let isDebugMode = this.isDebugMode();
      const { appointments } = this.state;
      let validAppointments = [...appointments].slice(index);

      //Clear all timeouts, if anything is waiting
      this.clearTimeouts();

      this.setState(
        { appointments: validAppointments, progress: SelfServiceViews.success },
        () => {
          if (isDebugMode)
            LogInformation(
              "showAppointment",
              "Moved to Success view - DONE",
              this.state.sessionid,
              this.state.selfServiceEventId
            );

          //Clear all timeouts, if anything is waiting
          this.clearTimeouts();

          let thankyouTimeout = this.getSafeTimeoutSetting(
            "THANKYOU_TIMEOUT",
            0
          );

          this.timeout = window.setTimeout(() => {
            //Move to start - welcome
            this.setState(
              {
                progress: this.hasSelectFrontPageEnabled()
                  ? SelfServiceViews.selectfrontpage
                  : SelfServiceViews.start,
                patients: [],
                appointments: [],
                registration: [],
                selfServiceEventId: null, //Clear current eventId when moving to startscreen
                frontpageFunction: null,
              },
              () => {
                this.changelanguage("fi"); //Change language back to finnish
              }
            );

            if (isDebugMode)
              LogInformation(
                "showAppointment",
                "Moved to Start view - DONE",
                this.state.sessionid,
                this.state.selfServiceEventId
              );
          }, thankyouTimeout);
        }
      );
    } catch (e) {
      this.handleErrorMessage(
        this.state.languages[this.state.lang].FAILED_FETCHING_DATA,
        this.state.languages[this.state.lang].NOTICE_TITLE,
        "red"
      );
    }
  };
  /***
   * Show error in ui,
   * clear timeouts
   * reset data,
   * move back to start after timeout
   */
  handleErrorMessage = (msg, title, color, patientname) => {
    //Clear all timeouts, if anything is waiting
    this.clearTimeouts();

    //Show error message to user
    this.setState(
      {
        appointments: [],
        patients: [],
        error: {
          message: msg,
          title: patientname ? patientname : title,
          color: color,
        },
        progress: SelfServiceViews.message, //Move to message view
      },
      () => {
        let messageTimeout = this.getSafeTimeoutSetting("MESSAGE_TIMEOUT", 0);

        //Set timeout to move back to start
        this.timeout = window.setTimeout(() => {
          this.setState(
            {
              progress: this.hasSelectFrontPageEnabled()
                ? SelfServiceViews.selectfrontpage
                : SelfServiceViews.start,
              patients: [],
              appointments: [],
              registration: [],
              selfServiceEventId: null, //Clear current eventId when moving to startscreen
              frontpageFunction: null,
            },
            () => {
              this.changelanguage("fi"); //Change language back to finnish
            }
          );
        }, messageTimeout);
      }
    );

    //Log errormessage
    LogError(title, msg, this.state.sessionid, this.state.selfServiceEventId);
  };
  /***
   * Change ui language
   * - Setup datetime library (moment) default language
   * - Set language to state
   */
  changelanguage = (lang) => {
    moment.locale(lang);
    this.setState({ lang: lang });
  };
  /***
   * Move to input patientId
   */
  toPatientIdInput = (e) => {
    e.preventDefault();
    this.clearTimeouts(); //Clear any timeouts if any
    this.setState({ progress: SelfServiceViews.patientidinput });
  };
  /**
   * User entered a patient Id
   */
  onPatientId = (patientId) => {
    this.getScheduleAndAutoregister(patientId);
  };
  /**
   * Set selected function to state
   * @param {*} frontpageFunction
   */
  onFrontPageFunctionSelected = (frontpageFunction) => {
    this.setState(
      {
        frontpageFunction: frontpageFunction,
        progress: SelfServiceViews.start,
      },
      () => {
        let welcomeTimeout = this.getSafeTimeoutSetting("WELCOME_TIMEOUT", 0);
        //Move back to start if user doesn't do anything
        this.timeout = window.setTimeout(() => {
          this.reset();
        }, welcomeTimeout);
      }
    );
  };

  /***
   * Get body based on progress
   */
  getBody() {
    const clientIp = new ApiCall().getClientIp();
    const {
      progress,
      languages,
      lang,
      patients,
      appointments,
      error,
      scheduledRefresh,
      settings,
    } = this.state;

    switch (progress) {
      case SelfServiceViews.selectfrontpage:
        //If scheduled refresh
        if (scheduledRefresh) {
          window.location.reload(true);
          return null;
        } else {
          return (
            <SelectFrontPage
              languages={languages[lang]}
              onSelect={this.onFrontPageFunctionSelected}
              functions={this.getSelectFrontPageFunctions()}
              clientIp={clientIp}
            />
          );
        }
      case SelfServiceViews.start:
        //If scheduled refresh
        if (scheduledRefresh) {
          window.location.reload(true);
          return null;
        } else {
          return (
            <Welcome
              languages={languages[lang]}
              inputPatientId={this.toPatientIdInput}
              keyboard={
                settings.PATIENT_ID_KEYBOARD &&
                settings.PATIENT_ID_KEYBOARD.toLowerCase() === "true"
              }
              reset={this.hasSelectFrontPageEnabled() ? this.reset : null}
              frontpageFunction={this.state.frontpageFunction}
              clientIp={clientIp}
            />
          );
        }
      case SelfServiceViews.patientidinput:
        return (
          <InputPatientId
            languages={languages[lang]}
            onpatientid={this.onPatientId}
            reset={this.reset}
          />
        );
      case SelfServiceViews.message:
        return (
          <MessagePage
            title={error.title}
            color={error.color}
            message={error.message}
            languages={languages[lang]}
            reset={this.reset}
          />
        );
      case SelfServiceViews.info:
        return (
          <Info
            languages={languages[lang]}
            patients={patients}
            appointments={appointments}
            canRegisterToMultiple={this.canRegisterToMultipleAppointments()}
            reset={this.reset}
            shownextofkins={
              settings.SHOW_NEXTOF_KINS === undefined ||
              settings.SHOW_NEXTOF_KINS.toLowerCase() === "true"
            }
            showpatient={
              settings.SHOW_PATIENT === undefined ||
              settings.SHOW_PATIENT.toLowerCase() === "true"
            }
          />
        );
      case SelfServiceViews.success:
        return (
          <Success
            languages={languages[lang]}
            lang={lang}
            appointments={appointments}
            reset={this.reset}
            image={settings.INFO_IMAGE}
            info={settings.INFO}
            canRegisterToMultiple={this.canRegisterToMultipleAppointments()}
          />
        );
      case SelfServiceViews.newappointment:
        return (
          <NewAppointment
            languages={languages[lang]}
            patients={patients}
            reset={this.reset}
            confirm={this.newAppointment}
          />
        );
      default:
        return null;
    }
  }

  /***
   * Send registration to appointment or appointments (slots)
   * - Send REST requests to register for appointments
   * - Set timeout to wait before moving to next view
   * - Move to success, if ok, and message view if not
   * - Setup timeout in success view, show success for only a timeout
   *   then move back to start = Welcome
   */

  /**
   *
   * @param {string patientid} patientid
   * @param {array of slots and implementations to register} registrations
   * @param {array of appointments where user registered} appointments
   * @returns array of results registrationResults
   */
  sendRegistration = async (patientid, registrations, appointments) => {
    try {
      let isDebugMode = this.isDebugMode();

      if (isDebugMode)
        LogInformation(
          "sendRegistration",
          "Sending registration for patient",
          this.state.sessionid,
          this.state.selfServiceEventId
        );

      let beforeRegisterTime = new Date();

      let registrationResults = [];
      let registrationToFirstAppointmentSuccess = false;

      for (let x = 0; x < registrations.length; x++) {
        let slotid = registrations[x].slotid;
        let implementation = registrations[x].implementation;

        let request = {
          PatientId: patientid,
          SlotId: slotid,
          Status: "",
          Error: "",
          Implementation: implementation,
          SelfServiceEventId: this.state.selfServiceEventId,
          Settings: this.state.frontpageFunction
            ? this.state.frontpageFunction.settings
            : {},
        };

        let registration = await new ApiCall().update(
          configuration.ROOT_URL + "/v1/Schedule/registration",
          undefined,
          request
        );

        registrationResults.push(registration);

        if (registration.Error && registration.Error !== "") break;
        else if (x === 0) registrationToFirstAppointmentSuccess = true;
      }

      let afterRegisterTime = new Date();

      //If REST request took a long time, user should not have to wait
      // calc time in milliseconds
      let registrationTimeMilliSeconds = afterRegisterTime - beforeRegisterTime;

      if (isDebugMode)
        LogInformation(
          "sendRegistration",
          "Sending registration for patient - DONE",
          this.state.sessionid,
          this.state.selfServiceEventId
        );

      if (registrationToFirstAppointmentSuccess === false) {
        this.handleErrorMessage(
          this.state.languages[this.state.lang].FAILED_TO_REGISTER,
          this.state.languages[this.state.lang].NOTICE_TITLE,
          "blue"
        );
      } else {
        //Clear all timeouts, if anything is waiting
        this.clearTimeouts();

        let registerTimeout = this.getSafeTimeoutSetting("REGISTER_TIMEOUT", 0);

        //If rest request took a long time, dont make user wait
        //Subtract the rest timeout from resulting timeout
        if (registerTimeout > registrationTimeMilliSeconds)
          registerTimeout = registerTimeout - registrationTimeMilliSeconds;

        //Set timeout to move to success -view
        this.timeout = window.setTimeout(() => {
          //Change view to success
          this.setState(
            {
              registration: registrationResults,
              progress: SelfServiceViews.success,
            },
            () => {
              if (isDebugMode)
                LogInformation(
                  "sendRegistration",
                  "Moved to Success view - DONE",
                  this.state.sessionid,
                  this.state.selfServiceEventId
                );

              //
              // Call print with selected appointment data
              //
              appointments.forEach((appointment) => {
                this.print(appointment);
              });

              //Setup timeout to move back to start

              //Clear all timeouts, if anything is waiting
              this.clearTimeouts();

              let thankyouTimeout = this.getSafeTimeoutSetting(
                "THANKYOU_TIMEOUT",
                0
              );

              this.timeout = window.setTimeout(() => {
                //Move to start - welcome
                this.setState(
                  {
                    progress: this.hasSelectFrontPageEnabled()
                      ? SelfServiceViews.selectfrontpage
                      : SelfServiceViews.start,
                    patients: [],
                    appointments: [],
                    registration: [],
                    selfServiceEventId: null, //Clear current eventId when moving to startscreen
                    frontpageFunction: null,
                  },
                  () => {
                    this.changelanguage("fi"); //Change language back to finnish
                  }
                );

                if (isDebugMode)
                  LogInformation(
                    "sendRegistration",
                    "Moved to Beginning view - DONE",
                    this.state.sessionid,
                    this.state.selfServiceEventId
                  );
              }, thankyouTimeout);
            }
          );
        }, registerTimeout);
      }
      return registrationResults;
    } catch (e) {
      this.handleErrorMessage(
        this.state.languages[this.state.lang].FAILED_FETCHING_DATA,
        this.state.languages[this.state.lang].NOTICE_TITLE,
        "red"
      );
    }
  };

  render() {
    const { settings } = this.state;
    return (
      <div className="page">
        <PageHeader
          changelanguage={this.changelanguage}
          logourl={settings.LOGO}
        />
        {this.getBody()}
      </div>
    );
  }
}
SelfService.propTypes = {
  barcode: PropTypes.string,
};
export default SelfService;
