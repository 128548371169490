import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid,Message,Button,Icon } from 'semantic-ui-react'
class NewAppointment extends Component {
    confirmOk = () => {
        const { confirm, patients, reset } = this.props;
        let patient = patients && patients.length > 0 ? patients[0] : undefined;
       // console.log("confirmOK",patient.PatientId, patient);
        if(patient)
            confirm(patient.PatientId);
        else 
            reset();
    }
    render() {
        const { languages, reset  } = this.props;
        
        return (
            <React.Fragment>
            <Grid className='message-grid'>
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column width="2"></Grid.Column>
                    <Grid.Column width="12">
                    <div className='newappointment-box'>
                        <Message color='grey' size='large'>
                            <Message.Header>{(languages ? languages.CANNOT_FIND_APPOINTMENTS_CREATE_APPOINTMENT : '')}</Message.Header>
                            <Message.Content className='newappointment-box-content'>
                                <Button icon labelPosition='left' color='blue' size='massive' onClick={this.confirmOk}>
                                    <Icon name='check circle' />
                                    {(languages ? languages.YES : 'Kyllä')}
                                </Button>
                                <Button icon labelPosition='left'  size='massive' onClick={reset}>
                                    <Icon name='close' />
                                    {(languages ? languages.NO : 'Ei')}
                                </Button>
                            </Message.Content>
                        </Message>
                    </div>
                    </Grid.Column>
                    <Grid.Column width="2"></Grid.Column>
                </Grid.Row>
            </Grid>
            <div className='back-button'>
                <Button icon labelPosition='left' color='green' size='massive' onClick={reset}>
                    <Icon name='step backward' />
                    {(languages ? languages.BACK_TO_START : 'Takaisin')}
                </Button>
            </div>
            </React.Fragment>
        );

    }
}

NewAppointment.propTypes = {
    languages: PropTypes.object.isRequired,
    patients: PropTypes.array,
    reset: PropTypes.func,
    confirm: PropTypes.func
}
export default NewAppointment;