import moment from 'moment';
import ApiCall from './../api/ApiCall';
import configuration from '../configuration';
import packageJson from "../../package.json";

export const logLevels = {
  /// Info
  info: 0,
  /// Warning
  warning: 1,
  /// Error
  error: 2,
};
/***
 * Log error to server
 */
export function LogError(title, msg, eventid, selfServiceEventId) {
  let clientLog = {
    Created: moment().toISOString(true),
    EventId: eventid,
    LogLevel: logLevels.error,
    Title: "Web: " + title,
    Message: "Client: " + msg + " - version:" + packageJson.version,
    SelfServiceEventId:
      selfServiceEventId === undefined ? null : selfServiceEventId,
  };
  console.error(clientLog);
  Log(clientLog);
}
/***
 * Log warning to server
 */
export function LogWarning(title, msg, eventid, selfServiceEventId) {
  let clientLog = {
    Created: moment().toISOString(true),
    EventId: eventid,
    LogLevel: logLevels.warning,
    Title: "Web: " + title,
    Message: msg + " - version:" + packageJson.version,
    SelfServiceEventId:
      selfServiceEventId === undefined ? null : selfServiceEventId,
  };
  console.warn(clientLog);
  Log(clientLog);
}
/***
 * Log information to server
 */
export function LogInformation(title, msg, eventid, selfServiceEventId) {
  let clientLog = {
    Created: moment().toISOString(true),
    EventId: eventid,
    LogLevel: logLevels.info,
    Title: "Web: " + title,
    Message: msg + " - version:" + packageJson.version,
    SelfServiceEventId:
      selfServiceEventId === undefined ? null : selfServiceEventId,
  };
  console.log(clientLog);
  Log(clientLog);
}
/***
 * Log to server
 */
export async function Log(clientlog){
    try{
        await new ApiCall().post(configuration.ROOT_URL + '/v1/Log/client',[clientlog]);
    }
    catch(e){
        //Failed to save log to server.... too bad
    }
}