import React from 'react';
import ReactDOM from "react-dom/client";
import "semantic-ui-css/semantic.min.css";
import "./index.css";
//import './attune.css';
//import './kallio.css';
//import './riihimaki.css';
import * as serviceWorker from "./serviceWorker";
import Routes from "./routes/Routes";
import "moment/locale/fi";
import moment from "moment";
moment.locale("fi"); //Set default locale

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Routes />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
