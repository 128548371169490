import React, { Component } from "react";
import configuration from "../configuration";
export const BarcodeContext = React.createContext("light");
/***
 * Listens for barcodes and calls function with barcode when barcode is read
 */
class BarcodeListener extends Component {
  constructor(props) {
    super(props);
    this.readKeys = "";
    this.readRawKeys = "";
    this.cleartimeout = null;
    this.state = {
      barcode: "",
      rawBarcode: "",
    };
    this.listenKeys = this.listenKeys.bind(this);
    this.clearKeys = this.clearKeys.bind(this);
  }
  componentDidMount() {
    //First remove listener, if already exists
    window.removeEventListener("keydown", this.listenKeys);
    //Then add new listener
    window.addEventListener("keydown", this.listenKeys);
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this.listenKeys);
  }
  /***
   * Pass only valid codes
   */
  inAllowedCodes = (code) => {
    //If current readkeybuffer start with S =>  it means it is new passport or id-card with QR-code
    //  Allow some more codes than normally
    if (this.readKeys.startsWith("S")) {
      if (code === 32)
        // ' ' (space)
        return true;

      if (code === 190)
        // . (period or if prev key was shift, then combined char is for example shift+190 => :)
        return true;
    }

    if (code === 45)
      // - (hyphenMinus)
      return true;

    if (code > 47 && code < 58)
      //0 - 9 (or if prev key was shift, then combined char is for example shift+7 => /)
      return true;

    if (code > 64 && code < 91)
      //a - z
      return true;

    if (code === 189)
      // - (dash)
      return true;

    if (code === 109)
      // - (subtract)
      return true;

    if (code === 173)
      // - (slash)
      return true;

    if (code === 191)
      // - (forwardslash)
      return true;

    return false;
  };
  extractPatientId = (value) => {
    if (value === null || value === "" || value === undefined) return "";

    const lengthOfPatientId = 11;

    if (value.startsWith("SMB1")) {
      //Is new QR-code, compare by length

      const lengthOfPassPortQRCode = 111;

      if (value.length === lengthOfPassPortQRCode) {
        //Passport

        /* Examplevalues
            SMB1.V2/MRY:P FINSPECIMEN PASS  VILMA SOFIA             XA10009201FIN9501016F2803135010398 132S   98/CAN:974573
            SMB1.V2/MRY:P FINSPECIMEN PASS  YOUNG PERSON            XA10009201FIN9501016F2803135121004A345B   98/CAN:974573
            SMB1.V2/MRY:P FINSPECIMEN PASS  YOUNG PERSON            XA10009201FIN9501016F2803135201110Y001X   98/CAN:974573
            SMB1.V2/MRY:P FINSPECIMEN PASS  YOUNG PERSON            XA10009201FIN9501016F2803135201110Y001X   98/CAN:974573
            SMB1.V2/MRY:P FINSPECIMEN PASS  YOUNG PERSON            XA10009201FIN9501016F2803135201110C222X   98/CAN:974573
            SMB1.V2/MRY:P FINSPECIMEN PASS  YOUNG PERSON            XA10009201FIN9501016F2803135220112D333J   98/CAN:974573

              ==> in this code, the code will be a bit different, because this code does not take [shift] into account that converts . to : and 7 to /
              / is converted to 7
              : is converted to .
              ==> The length is still the same, so we can get the patientid by index
              SMB1.V27MRY.P FINSPECIMEN PASS  YOUNG PERSON            XA10009201FIN9501016F2803135220112D333J   987CAN.974573
                                                                                                  ^
                                                                                                  - PatientId starts here at index - 85 =>  220112D333J     
            */
        return value.length < 85 + lengthOfPatientId
          ? value
          : value.substring(85, lengthOfPatientId).replace(" ", "-");
      } else {
        //Id card

        /* Examplevalues
            SMB1.V2/MRY:I FINHA00015186010195 112X    9501016F2803135FIN           5SPECIMEN TRAVEL  VILMA SOFIA  /CAN:378065
            SMB1.V2/MRY:I FINHA00015186121267 234X    9501016F2803135FIN           5SPECIMEN TRAVEL  VILMA SOFIA  /CAN:378065
            SMB1.V2/MRY:I FINHA00015186231204A555S    9501016F2803135FIN           5SPECIMEN TRAVEL  VILMA SOFIA  /CAN:378065
            SMB1.V2/MRY:I FINHA00015186030594W903B    9501016F2803135FIN           5SPECIMEN TRAVEL  VILMA SOFIA  /CAN:378065
            SMB1.V2/MRY:I FINHA00015186050594U902L    9501016F2803135FIN           5SPECIMEN TRAVEL  VILMA SOFIA  /CAN:378065
            SMB1.V2/MRY:I FINHA00015186020516C902J    9501016F2803135FIN           5SPECIMEN TRAVEL  VILMA SOFIA  /CAN:378065
            SMB1.V2/MRY:I FINHA00015186030516D9037    9501016F2803135FIN           5SPECIMEN TRAVEL  VILMA SOFIA  /CAN:378065
            SMB1.V2/MRY:I FINHA00015186010501E9032    9501016F2803135FIN           5SPECIMEN TRAVEL  VILMA SOFIA  /CAN:378065
            SMB1.V2/MRY:I FINHA00015186020503F9037    9501016F2803135FIN           5SPECIMEN TRAVEL  VILMA SOFIA  /CAN:378065

            ==> in this code, the code will be a bit different, because this code does not take [shift] into account that converts . to : and 7 to /
            / is converted to 7
            : is converted to .
            ==> The length is still the same, so we can get the patientid by index
            SMB1.V27MRY.I FINHA00015186020503F9037    9501016F2803135FIN           5SPECIMEN TRAVEL  VILMA SOFIA  7CAN.378065
                                      ^
                                      - PatientId starts here at index - 28 =>  020503F9037       
          */
        return value.length < 28 + lengthOfPatientId
          ? value
          : value.substring(28, lengthOfPatientId).replace(" ", "-");
      }
    } else {
      //Plain old patientID, not the newer QR-code

      return value;
    }
  };
  listenKeys(e) {
    const endCode = 13; //13 = Enter, final char of complete barcode
    const dash = 189;
    const hyphenMinus = 45;
    const subtract = 109;
    const slash = 173;
    const forwardslash = 191;
    const period = 190;

    //Remove cleartimeout
    if (this.cleartimeout !== null && this.cleartimeout !== undefined) {
      window.clearTimeout(this.cleartimeout);
      this.cleartimeout = null;
    }

    //Get the key, keyCode or which - contains the key depending on the browser
    //This || means, it will get the one that is not undefined
    var code = e.keyCode || e.which;

    var key =
      code === dash ||
      code === subtract ||
      code === hyphenMinus ||
      code === slash ||
      code === forwardslash
        ? "-"
        : code === period
        ? "."
        : String.fromCharCode(code);
    var rawKey =
      code === dash ||
      code === subtract ||
      code === hyphenMinus ||
      code === slash ||
      code === forwardslash
        ? "-"
        : code === period
        ? "."
        : e.key;

    //If endchar is received, then barcode is read, and set current barcode as 'readKeys'
    if (code === endCode) {
      if (configuration.TestPatientId && configuration.TestPatientId !== "")
        this.readKeys = configuration.TestPatientId; //Tämä on demotusta varten
      //Set read barcode to state => this will be sent to child components
      this.setState({
        barcode: this.extractPatientId(this.readKeys),
        rawBarcode: this.readRawKeys,
      });
    } else if (this.inAllowedCodes(code)) {
      this.readKeys += key.toUpperCase(); //Add new key to buffer

      if (rawKey.length === 1) this.readRawKeys += rawKey; //Add new key to rawbuffer
    } else {
      if (rawKey.length === 1) this.readRawKeys += rawKey; //Add new key to rawbuffer
    }
    //Set cleartimeout to clear 'readKeys' string, and barcode from state, after x second
    this.cleartimeout = window.setTimeout(
      this.clearKeys,
      configuration.BarcodeTimeout
    );
  }
  //Clear read keys "buffer" and clear barcode from state
  clearKeys() {
    this.readKeys = "";
    this.readRawKeys = "";
    this.setState({ barcode: "", rawBarcode: "" });
  }
  render() {
    return (
      <BarcodeContext.Provider value={this.state}>
        {this.props.children}
      </BarcodeContext.Provider>
    );
  }
}
export default BarcodeListener;
