import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid,Message,Button,Icon } from 'semantic-ui-react'
class MessagePage extends Component {

    render() {
        const { title, message, secondmessage, color, reset, languages } = this.props;
        let empty = title === undefined && message === undefined && secondmessage === undefined;
        let hardcodemessage = "Tietojen haku epäonnistui";
        return (
            <React.Fragment>
            <Grid className='message-grid'>
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column width="2"></Grid.Column>
                    <Grid.Column width="12">
                    <div className='message-box'>
                        <Message color={color} size='large'>
                            {title && <Message.Header>{title}</Message.Header>}
                            {message && <p>{message}</p>}
                            {secondmessage && <p>{secondmessage}</p>}
                            {empty && <Message.Header>{hardcodemessage}</Message.Header>}
                        </Message>
                    </div>
                    </Grid.Column>
                    <Grid.Column width="2"></Grid.Column>
                </Grid.Row>
            </Grid>
            <div className='back-button'>
                <Button icon labelPosition='left' color='green' size='massive' onClick={reset}>
                    <Icon name='step backward' />
                    {(languages ? languages.BACK_TO_START : 'Takaisin')}
                </Button>
            </div>
            </React.Fragment>
        );

    }
}
MessagePage.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string,
    secondmessage: PropTypes.string,
    reset: PropTypes.func,
    languages: PropTypes.object.isRequired
}
export default MessagePage;