import React, { Component } from 'react';
import moment from 'moment';
import { LogError } from '../helpers/Log.js';

class ErrorHandler extends Component {

    constructor(props){
        super(props);
        this.parseError = this.parseError.bind(this);
        this.onError = this.onError.bind(this);
    }
    
    componentDidMount(){
        window.removeEventListener("error", this.onError.bind(this));
        window.addEventListener("error", this.onError.bind(this));
    }
    
    componentWillUnmount(){
        window.removeEventListener("error", this.onError.bind(this));
    }
    /**
     * Try to parse error to more readable
     * @param {*} e 
     */
	parseError(e){
        
        
        try{
            let currentUrl = window.location.href;

            let errormessage = e.message;
            let errorstack = "";

            if(e.originalEvent){
                errormessage = e.originalEvent.error.toString();

                if (typeof e.originalEvent.error.stack !== "undefined")
                    errorstack = e.originalEvent.error.stack;
            }
            
            let timestampInJs = moment().toISOString(true);

            return "Javascript error: " + timestampInJs + " | " + errormessage + " | " + currentUrl + " | " + errorstack;
        }
        catch(error){
            //fallback, return whole original error object
            return "Javascript error: " + JSON.stringify(e);
        }
    }

    /**
     * Capture error, and log it to server
     */
    onError(e){
        e.preventDefault();

        LogError("Unhandled", this.parseError(e), "");

        return true;
    }
    
    render() {
        return <React.Fragment>{this.props.children}</React.Fragment>;
    }
}

export default ErrorHandler;