import React, { Component } from "react";
import { Grid, Icon, Button, Divider } from "semantic-ui-react";
import PropTypes from "prop-types";
import moment from "moment";
import configuration from "../configuration";

class Success extends Component {
  render() {
    const {
      languages,
      lang,
      appointments,
      reset,
      info,
      image,
      canRegisterToMultiple,
    } = this.props;
    let pageInfo = info;
    let pageImage = image;
    let successAppointment =
      appointments && appointments.length > 0 ? appointments[0] : undefined;

    let langKey = "Finnish";
    if (lang === "fi") langKey = "Finnish";
    else if (lang === "se") langKey = "Swedish";
    else if (lang === "en") langKey = "English";
    else if (lang === "ru") langKey = "Russian";

    //Override info and image, if not set
    if (
      (pageInfo === undefined || pageInfo === "") &&
      successAppointment &&
      successAppointment.Directions
    )
      pageInfo = successAppointment.Directions[langKey];
    if (
      (pageImage === undefined || pageImage === "") &&
      successAppointment &&
      successAppointment.Directions
    )
      pageImage =
        configuration.FILES_ROOT_URL +
        `/v1/file?filename=${window.encodeURIComponent(
          successAppointment.Directions.Image
        )}`;

    let isPortrait =
      window.parseFloat(window.innerWidth) <
      window.parseFloat(window.innerHeight);
    return (
      <div className="success-container">
        <Grid className="success-grid">
          {pageInfo && pageInfo !== "" && (
            <Grid.Row>
              <Grid.Column
                width={isPortrait ? "2" : "1"}
                textAlign="right"
                verticalAlign="top"
              >
                <div className="info-icon">
                  <Icon name="info circle" size="huge"></Icon>
                </div>
              </Grid.Column>

              <Grid.Column
                width={
                  pageImage && pageImage !== "" && !isPortrait ? "11" : "14"
                }
                verticalAlign="top"
              >
                <div className="info">
                  <div className="info-desc">
                    {pageInfo.split("\n").map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          {item}
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          )}

          {isPortrait && pageImage && pageImage !== "" && (
            <Grid.Row>
              <Grid.Column width="2"></Grid.Column>
              <Grid.Column width="14">
                <div className="info-image">
                  <img src={pageImage} alt="info" />
                </div>
              </Grid.Column>
            </Grid.Row>
          )}
          {(canRegisterToMultiple ? appointments : [appointments[0]]).map(
            (appointment, indx) => {
              return (
                <React.Fragment
                  key={"success-appointment-slotid-" + appointment.SlotId}
                >
                  <Grid.Row>
                    <Grid.Column
                      width={isPortrait ? "2" : "1"}
                      textAlign="right"
                      verticalAlign="top"
                    >
                      <div className="clock-icon">
                        <Icon name="clock outline" size="huge"></Icon>
                      </div>
                    </Grid.Column>

                    <Grid.Column
                      width={
                        pageImage && pageImage !== "" && !isPortrait
                          ? "11"
                          : "14"
                      }
                    >
                      <div className="successappointment">
                        {appointment && (
                          <div className="successappointment-time">
                            {(languages ? languages.AT_TIME : "") +
                              " " +
                              moment(appointment.StartDateTime)
                                .utc()
                                .format("HH:mm")}
                          </div>
                        )}
                        {appointment &&
                          appointment.Description &&
                          appointment.Description !== "" && (
                            <div className="successappointment-desc">
                              {appointment.Description}
                            </div>
                          )}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column
                      width={isPortrait ? "2" : "1"}
                      textAlign="right"
                      verticalAlign="top"
                    >
                      <div className="location-icon">
                        <Icon name="map signs" size="huge"></Icon>
                      </div>
                    </Grid.Column>

                    <Grid.Column
                      width={
                        pageImage && pageImage !== "" && !isPortrait
                          ? "11"
                          : "14"
                      }
                    >
                      {appointment && appointment.Locations && (
                        <div className="successappointment-room">
                          {appointment.Locations.map((location, index) => (
                            <span key={"success-location-" + index}>
                              {(index !== 0 ? ", " : "") + location}
                            </span>
                          ))}
                        </div>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  {indx < appointments.length - 1 && canRegisterToMultiple && (
                    <Divider />
                  )}
                </React.Fragment>
              );
            }
          )}

          <Grid.Row>
            <Grid.Column width={isPortrait ? "2" : "1"}></Grid.Column>
            <Grid.Column width={!isPortrait ? "15" : "14"}>
              <div className="success-thankyou">
                {languages ? languages.THANKYOU_TEXT : ""}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {!isPortrait && pageImage && pageImage !== "" && (
          <div className="info-image">
            <img src={pageImage} alt="info" />
          </div>
        )}
        <div className="back-button">
          <Button
            icon
            labelPosition="left"
            color="green"
            size="massive"
            onClick={reset}
          >
            <Icon name="step backward" />
            {languages ? languages.BACK_TO_START : ""}
          </Button>
        </div>
      </div>
    );
  }
}
Success.propTypes = {
  appointments: PropTypes.array,
  lang: PropTypes.string,
  languages: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  image: PropTypes.string,
  info: PropTypes.string,
  canRegisterToMultiple: PropTypes.bool,
};
export default Success;
