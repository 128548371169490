import React, { Component } from "react";
import Form from "semantic-ui-react/dist/commonjs/collections/Form/Form";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment/Segment";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import packageJson from "../../package.json";
import PropTypes from "prop-types";
import ApiCall from "../api/ApiCall";
import configuration from "../configuration";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autologinMessage: null,
    };
    this.submitLogin = this.submitLogin.bind(this);
  }
  componentDidMount() {
    this.setCurrentClietIpFromLocalStorageToForm();
    this.autoSignIn();
  }
  autoSignIn = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const noAutologin = urlParams.get("noauto");
    const { login } = this.props;

    try {
      if (
        window.PasswordCredential &&
        window.PublicKeyCredential &&
        noAutologin === null
      ) {
        navigator.credentials
          .get({ password: true })
          .then((cred) => {
            if (cred) {
              const seconds = 5;
              this.setState(
                {
                  autologinMessage:
                    "Kirjaudutaan automaattisesti sisään " +
                    seconds +
                    "s päästä...",
                },
                () => {
                  const currentClientIp = new ApiCall().clientIp;
                  window.setTimeout(() => {
                    login(null, cred.id, cred.password, currentClientIp);
                  }, seconds * 1000);
                }
              );
            }
          })
          .catch((err) => {
            console.log("PasswordCredential error", err);
          });
      }
    } catch (err) {
      console.log("PasswordCredential error", err);
    }
  };
  setCurrentClietIpFromLocalStorageToForm = () => {
    const currentClientIp = new ApiCall().clientIp;
    if (currentClientIp) {
      this.clientIp = document.getElementById("clientip");
      this.clientIp.value = currentClientIp;
    }
  };
  submitLogin(e) {
    e.preventDefault();
    const { login } = this.props;
    this.uname = document.getElementById("uname");
    this.pwd = document.getElementById("pwd");
    this.clientIp = document.getElementById("clientip");
    login(e, this.uname.value, this.pwd.value, this.clientIp.value);
  }
  getMyIpToForm = async () => {
    try {
      const api = new ApiCall(true);
      const baseUrl = configuration.ROOT_URL.endsWith("/")
        ? configuration.ROOT_URL
        : configuration.ROOT_URL + "/";
      const response = await api.get(`${baseUrl}v1/Client/ip`);
      document.getElementById("clientip").value = response;
    } catch (error) {
      console.warn("Failed to get my ip, ignore");
    }
  };
  render() {
    const { loading, error } = this.props;
    const { autologinMessage } = this.state;
    return (
      <React.Fragment>
        <Form size="large" onSubmit={this.submitLogin}>
          <Segment>
            {autologinMessage && <Message>{autologinMessage}</Message>}
            <Form.Input
              fluid
              required
              name="username"
              autoComplete="username"
              icon="user"
              id="uname"
              iconPosition="left"
              placeholder="Käyttäjänimi"
            />
            <Form.Input
              fluid
              required
              name="password"
              icon="lock"
              id="pwd"
              iconPosition="left"
              placeholder="Salasana"
              type="password"
              autoComplete="current-password"
            />
            <Form.Input
              fluid
              name="clientip"
              autoComplete="off"
              id="clientip"
              placeholder="Tunniste tai ip-osoite"
              action
            >
              <input />
              <Button
                type="button"
                color="teal"
                onClick={this.getMyIpToForm}
                title="Hae ip-osoite rest-palvelulta lomakkeelle"
              >
                Hae ip
              </Button>
            </Form.Input>
            <Button
              fluid
              size="large"
              type="submit"
              color="google plus"
              loading={loading}
            >
              Kirjaudu
            </Button>

            {error && error !== "" && <Message color="red">{error}</Message>}
          </Segment>
        </Form>

        <br />
        {packageJson.description + " [ v" + packageJson.version + " ]"}
        <br />
        {packageJson.author}
        <br />
      </React.Fragment>
    );
  }
}
LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default LoginForm;
