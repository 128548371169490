import React from "react";
import moment from 'moment';

class Clock extends React.Component {

  constructor(props) {
    super(props);
    
    this.interval = null;
    this.state = {
        time: moment().format('D.M.YYYY HH:mm')
    };
  
  }
  
  componentDidMount() {
    this.interval = window.setInterval(
        () => this.tick(),
        1000);
  }
  
  componentWillUnmount() {
    if(this.interval && this.interval !== null)
      window.clearInterval(this.interval);
  }
  
  tick() {
    this.setState({
      time: moment().format('D.M.YYYY HH:mm')
    });
  }
  
  render() {
      return (<div className='clock-time'>{this.state.time}</div>);
  }
  
}
export default Clock;
