import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Loader, Button, Icon } from "semantic-ui-react";
import moment from "moment";

class Info extends Component {
  render() {
    const {
      languages,
      appointments,
      patients,
      reset,
      shownextofkins,
      showpatient,
      canRegisterToMultiple,
    } = this.props;
    let nextAppointment =
      appointments && appointments.length > 0 ? appointments[0] : undefined;
    let laterAppointment =
      appointments && appointments.length > 1 ? appointments[1] : undefined;
    let patient = patients && patients.length > 0 ? patients[0] : undefined;

    return (
      <React.Fragment>
        <Grid className="info-grid" stackable>
          <Grid.Row>
            <Grid.Column width="10">
              {!patient && (
                <div className="nextappointment-loading">
                  <Loader active inline size="large" />{" "}
                  {languages ? languages.LOADING_DATA : ""}...{" "}
                </div>
              )}
              {nextAppointment && (
                <div className="nextappointment">
                  <div className="nextappointment-title">
                    {languages ? languages.NEXT_APPOINTMENT_TEXT : ""}
                  </div>
                  <div className="nextappointment-desc">
                    {nextAppointment.Description}
                  </div>
                  <div className="nextappointment-time">
                    <Icon name="clock outline"></Icon>{" "}
                    {(languages ? languages.AT_TIME : "") +
                      " " +
                      moment(nextAppointment.StartDateTime)
                        .utc()
                        .format("HH:mm")}
                  </div>
                  {patient && canRegisterToMultiple === false && (
                    <div className="nextappointment-checkintime">
                      <Loader active inline size="large" />{" "}
                      {languages ? languages.CHECKING_IN_TEXT : ""}...{" "}
                    </div>
                  )}
                </div>
              )}
              {laterAppointment && (
                <React.Fragment>
                  <div className="laterappointment">
                    <div className="laterappointment-title">
                      {languages ? languages.LATER : ""}
                    </div>
                    <div className="laterappointment-desc">
                      {laterAppointment.Description}
                    </div>
                    <div className="laterappointment-time">
                      <Icon name="clock outline"></Icon>{" "}
                      {(languages ? languages.AT_TIME : "") +
                        " " +
                        moment(laterAppointment.StartDateTime)
                          .utc()
                          .format("HH:mm")}
                    </div>
                  </div>
                </React.Fragment>
              )}
              {patient && canRegisterToMultiple === true && (
                <div className="nextappointment">
                  <div className="nextappointment-checkintime">
                    <Loader active inline size="large" />{" "}
                    {languages ? languages.CHECKING_IN_TEXT : ""}...{" "}
                  </div>
                </div>
              )}
            </Grid.Column>
            <Grid.Column width="6">
              <React.Fragment>
                {showpatient && patient && (
                  <div className="patient">
                    <Icon name="user"></Icon>{" "}
                    <div className="patient-title">
                      {patient.FirstNames + " " + patient.LastName}
                    </div>
                    {patient.Addresses.length > 0 && (
                      <div className="patient-address">
                        {patient.Addresses.map((address, index) => (
                          <span key={patient.FirstNames + "-" + address}>
                            {(index > 0 ? ", " : "") + address}
                          </span>
                        ))}
                      </div>
                    )}
                    <div className="patient-phone" key={patient.PhoneNumber}>
                      {patient.PhoneNumber}
                    </div>
                  </div>
                )}

                {shownextofkins &&
                  patient &&
                  patient.NextOfKins &&
                  patient.NextOfKins.slice(0, 1).map((nextofkin, idx) => (
                    <div
                      className="nextofkin"
                      key={nextofkin.FirstNames + "-" + idx}
                    >
                      <div className="nextofkin-text-title">
                        {languages ? languages.NEXTOFKIN : ""}
                      </div>
                      <div
                        className="nextofkin-title"
                        key={nextofkin.FirstNames}
                      >
                        {nextofkin.FirstNames + " " + nextofkin.LastName}
                      </div>
                      {nextofkin.Addresses.length > 0 && (
                        <div className="nextofkin-address">
                          {nextofkin.Addresses.map((address, index) => (
                            <span key={nextofkin.FirstNames + "-" + address}>
                              {(index > 0 ? ", " : "") + address}
                            </span>
                          ))}
                        </div>
                      )}
                      <div
                        className="nextofkin-phone"
                        key={nextofkin.PhoneNumber}
                      >
                        {nextofkin.PhoneNumber}
                      </div>
                    </div>
                  ))}
              </React.Fragment>

              {patient && (
                <div className="notifychanges">
                  {languages ? languages.NOTIFY_CHANGES_TO_STAFF_TEXT : ""}
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <div className="back-button">
          <Button
            icon
            labelPosition="left"
            color="green"
            size="massive"
            onClick={reset}
          >
            <Icon name="step backward" />
            {languages ? languages.BACK_TO_START : "Takaisin"}
          </Button>
        </div>
      </React.Fragment>
    );
  }
}
Info.propTypes = {
  patients: PropTypes.array,
  appointments: PropTypes.array,
  languages: PropTypes.object.isRequired,
  shownextofkins: PropTypes.bool.isRequired,
  showpatient: PropTypes.bool.isRequired,
  reset: PropTypes.func,
  canRegisterToMultiple: PropTypes.bool,
};
export default Info;
